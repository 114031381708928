<template>
  <div class="logistic-report-container mb-3 mt-3">
    <div
      class="header-container display-flex justify-space-between align-center width-100"
    >
      <h1>Logistics Report</h1>
      <MonthPicker
        :key="monthPickerKey"
        :month="monthText"
        :year="year"
        @change="monthPicked"
        @reset="resetClicked"
      />
    </div>
    <Spinner class="spinner-class exact-center" size="30" v-if="isLoading" />
    <v-data-table
      v-else
      :headers="headers"
      :items="logisticsReport"
      item-key="name"
      hide-default-footer
      :server-items-length="totalLogisticsReport"
      @update:page="updatePagination($event)"
      :options="{ page: logsiticsReportPage, itemsPerPage: 5 }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        />
      </template>
      <template v-slot:item.name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.company_img"
            :url="`${serverUrl}/${item.company_img}`"
            :name="item.company_name"
            size="50"
            radius="12"
          />
          <Avatar v-else :name="item.company_name" size="50" radius="12" />
          <div class="name ml-4">
            <h1>{{ item.company_name ? item.company_name : "-" }}</h1>
          </div>
        </div>
      </template>

      <template v-slot:item.total_orders="{ item }">
        <div class="orders-container display-flex align-center">
          <div class="count">
            <h1>{{ item.ordercount }}</h1>
            <h2 class="mt-2">Total Orders</h2>
          </div>
          <div class="progress exact-center ml-2">
            <img width:="10" height="20" src="@/assets/icons/increment.svg"
            alt="order-progress" />
          </div>
        </div>
      </template>

      <template v-slot:item.pendings="{ item }">
        <div class="orders-container display-flex align-center">
          <div class="order-image mr-2">
            <img src="@/assets/icons/total-merchant.svg" />
          </div>
          <div class="count">
            <h1>
              {{
                getPendingOrders(
                  item.ordercount,
                  item.ordercompleted,
                  item.orderfailed
                )
              }}
            </h1>
            <h2 class="mt-2">Order Pendings</h2>
          </div>
          <div class="progress exact-center ml-2">
            <img width:="10" height="20" src="@/assets/icons/increment.svg"
            alt="order-progress" />
          </div>
        </div>
      </template>

      <template v-slot:item.delivered="{ item }">
        <div class="orders-container display-flex align-center">
          <div class="order-image mr-2">
            <img src="@/assets/icons/frame.svg" />
          </div>
          <div class="count">
            <h1>{{ item.ordercompleted }}</h1>
            <h2 class="mt-2">Order Delivered</h2>
          </div>
          <div class="progress exact-center ml-2">
            <img width:="10" height="20" src="@/assets/icons/increment.svg"
            alt="order-progress" />
          </div>
        </div>
      </template>

      <template v-slot:item.cancelled="{ item }">
        <div class="orders-container display-flex align-center">
          <div class="order-image mr-2">
            <img src="@/assets/icons/cancelled.svg" />
          </div>
          <div class="count">
            <h1>{{ item.orderfailed }}</h1>
            <h2 class="mt-2">Order Cancelled</h2>
          </div>
          <div class="progress exact-center ml-2">
            <img width:="10" height="20" src="@/assets/icons/increment.svg"
            alt="order-progress" />
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL } from "../../../../constants";
import moment from "moment";
import MonthPicker from "./MonthPicker.vue";

export default {
  name: "LogisticReport",
  components: { MonthPicker },
  data() {
    return {
      serverUrl: SERVER_URL,
      isLoading: false,
      monthPickerKey: 0,
      monthText: "",
      month: "",
      year: "",
      headers: [
        {
          text: "Logistic Name",
          align: "start",
          sortable: false,
          value: "name",
          width: "210px",
        },
        {
          text: "Total Orders",
          align: "start",
          sortable: false,
          value: "total_orders",
          width: "170px",
        },
        {
          text: "Orders Pendings",
          align: "start",
          sortable: false,
          value: "pendings",
          width: "250px",
        },
        {
          text: "Order Delivered",
          align: "start",
          sortable: false,
          value: "delivered",
          width: "250px",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getLogisticsReport: "admin/dashboard/getLogisticsReport",
      setLogisiticsReportPage: "admin/dashboard/setLogisiticsReportPage",
    }),
    updatePagination(value) {
      this.setLogisiticsReportPage(value);
      this.fetchLogisiticsReport();
    },
    getPendingOrders(total, done, cancelled) {
      return Number(total) - (Number(done) + Number(cancelled));
    },
    async resetClicked() {
      this.month = "";
      this.year = "";
      this.monthText = "";
      this.monthPickerKey += 1;
      await this.fetchLogisiticsReport();
    },
    async monthPicked(month) {
      this.month = moment(month).format("M");
      this.monthText = moment(month).format("MMM");
      this.year = moment(month).format("YYYY");
      await this.fetchLogisiticsReport();
    },
    async fetchLogisiticsReport() {
      this.isLoading = true;
      await this.getLogisticsReport({ month: this.month, year: this.year });
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("admin/dashboard", [
      "logisticsReport",
      "totalLogisticsReport",
      "logsiticsReportPage",
    ]),
  },
  async created() {
    this.setLogisiticsReportPage(1);
    await this.fetchLogisiticsReport();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.logistic-report-container {
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    border-bottom: 4px solid $white-color !important;
    padding: 18px 0;
  }
  ::v-deep .v-data-footer__select {
    visibility: hidden;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead {
    visibility: hidden;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    height: 0px !important;
    border-bottom: 1px solid $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    .v-data-footer__select,
    .v-data-footer__pagination {
      font-size: 12px;
      color: $secondary-color;
    }
    border: none;
  }
  height: 650px;
  background: $white-color;
  border: 1px solid #ebebeb;
  padding: 3rem;
  .name {
    h1 {
      font-weight: 600;
      font-size: 0.875rem;
      color: $secondary-color;
    }
    h2 {
      font-weight: 500;
      font-size: 0.813rem;
      color: #b5b5c3;
    }
  }
  .orders-container {
    .order-image {
      width: 36px;
      height: 36px;
      img {
        width: 100%;
      }
    }
    .progress {
      width: 30px;
      height: 30px;
      background: transparent;
      img {
        width: 100%;
      }
    }
    .count {
      h1 {
        font-weight: 600;
        font-size: 0.875rem;
        color: $secondary-color;
      }
      h2 {
        font-weight: 400;
        font-size: 0.813rem;
        color: #b5b5c3;
      }
    }
  }
  .header-container {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
      color: #000000;
    }
  }
}
</style>
