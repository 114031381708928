<template>
  <v-dialog :value="show" :max-width="width" persistent>
    <div class="width-100 display-flex flex-col pa-8">
      <!-- title -->
      <div class="modal-title display-flex width-100 align-center justify-space-between">
        {{ title }}
        <v-btn @click="onCancel" icon dark>
          <img src="@/assets/icons/close.svg" />
        </v-btn>
      </div>
      <!-- body -->
      <div v-for="item in customersData" :key="item.id" class="customer-container">
        <div class="display-flex align-center width-100">
          <Avatar :name="item.user_first_name" size="60" radius="12" />
          <div class="name width-100 ml-4">
            <div class="display-flex align-center width-100 justify-space-between">
              {{ item.user_first_name ? item.user_first_name : "-" }}
              <div class="phone_number">
                {{ item.contact_number ? item.contact_number : "-" }}
              </div>
            </div>
            <div class="address mt-2">
              {{ item.address ? item.address : "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PromotionCustomerList",
  props: ["show", "width", "title"],
  data() {
    return {
      isLoading: false,
      customers: [
        {
          id: 1,
          name: "Brad Simmons",
          address: "3452 Harper Street, United States, New Jersey, Trenton 641001",
          number: "+91 9876543210",
        },
        {
          id: 2,
          name: "Jessie Clarcson",
          address: "3452 Harper Street, United States, New Jersey, Trenton 641001",
          number: "+91 9876543210",
        },
        {
          id: 3,
          name: "Lebron Wayde",
          address: "3452 Harper Street, United States, New Jersey, Trenton 641001",
          number: "+91 9876543210",
        },
        {
          id: 4,
          name: "Natali Trump",
          address: "3452 Harper Street, United States, New Jersey, Trenton 641001",
          number: "+91 9876543210",
        },
      ],
    };
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    onSave() {
      this.isLoading = true;
    },
  },
  computed: {
    ...mapGetters("admin/promotion", ["customersData"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
  //   overflow-y: scroll;
}
.customer-container {
  margin-bottom: 1.5rem;
}
.name {
  font-weight: 600;
  font-size: 0.875rem;
  color: #464e5f;
}
.phone-number {
  font-size: 0.75rem;
}
.address {
  color: #b5b5c3;
  font-weight: 500;
  font-size: 0.813rem;
  width: 200px;
}
.modal-title {
  color: #000000;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 2.875rem;
}
</style>
