<template>
  <div class="merchant-inner-table-container width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete ${selectedType}`"
      cancel="Cancel"
      :content="
        `Are you sure you want to delete the selected ${selectedType}? `
      "
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <div
      class="inner-table-header display-flex justify-space-between align-center"
    >
      <div class="display-flex align-center">
        <img
          v-if="selectedType === 'Warehouse'"
          src="@/assets/icons/warehouse.svg"
          class="mb-1"
        />
        <img
          v-else-if="selectedType === 'Orders'"
          src="@/assets/icons/orders.svg"
          class="mb-1"
        />
        <img v-else src="@/assets/icons/user.svg" class="mb-1" />
        <div class="table-title ml-2">{{ selectedType }}</div>
        <SearchInput
          class="ml-4"
          @change="onSearch"
          :value="searchValue"
          :autofocus="true"
        />
        <DateFilter
          v-if="!hideFilter"
          class="ml-4"
          :isFilter="isFilter"
          @filterClicked="filterClicked"
          @resetClicked="resetClicked"
          :dateFilterType="dateFilterType"
          :keyValue="keyValue"
        />
       <!-- Slot for Add on Filters -->
       <slot v-if="selectedType === 'Orders'"></slot>
        <Button
          v-if="selectedType === 'Orders'"
          @click="onExportOrder"
          label="Export"
          type="secondary"
          width="137px"
          class="ml-4"
        />
      </div>
      <div class="button-container" v-if="loggedUserPermission.edit_merchants">
        <Button
          label="+ Add Warehouse"
          type="cancel"
          width="94px"
          v-if="selectedType === 'Warehouse'"
          @click="onAddWareHouse"
        />
        <Button
          label="+ Add Users"
          type="cancel"
          v-if="selectedType === 'Users'"
          width="94px"
          @click="onAddUsers"
        />
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :key="keyValue"
      :items="items"
      item-key="name"
      hide-default-footer
      show-expand
      :server-items-length="totalItem"
      @update:page="updatePagination($event)"
      :options="{ page: page, itemsPerPage: 5 }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          show-first-last-page
        />
      </template>
      <template v-slot:item.user_name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.user_img"
            :url="`${serverUrl}/${item.user_img}`"
            :name="item.user_name"
            size="50"
            radius="12"
          />
          <Avatar v-else :name="item.user_name" size="50" radius="12" />
          <div v-if="selectedType === 'Users'" class="name ml-2">
            {{ item.user_name ? item.user_name : "-" }}
          </div>
          <div v-else class="name">{{ item.name ? item.name : "-" }}</div>
        </div>
      </template>

      <!-- Warehouse -->
      <template v-slot:item.warehouse_name="{ item }">
        <div class="name">
          {{ item.warehouse_name ? item.warehouse_name : "-" }}
        </div>
      </template>

      <template v-slot:item.contact_person="{ item }">
        <div class="name">
          {{ item.contact_person ? item.contact_person : "-" }}
        </div>
      </template>

      <template v-slot:item.contact_number="{ item }">
        <div class="name">
          {{ item.contact_number ? item.contact_number : "-" }}
        </div>
      </template>

      <template v-slot:item.phone_number="{ item }">
        <div class="name">
          {{ item.phone_number ? item.phone_number : "-" }}
        </div>
      </template>

      <template v-slot:item.address="{ item }">
        <div class="address-1">
          {{ item.block_number ? item.block_number + ", " : ""
          }}{{ item.floor_number ? item.floor_number + ", " : ""
          }}{{ item.address ? item.address : "-" }}
        </div>
        <div class="address-2" v-if="item.address">
          {{ item.cityortown ? item.cityortown + ", " : ""
          }}{{ item.state ? item.state + ", " : ""
          }}{{ item.country ? item.country : "" }}
        </div>
      </template>

      <template v-slot:item.phone_number="{ item }">
        <div class="name">
          {{ item.phone_number ? item.phone_number : "-" }}
        </div>
      </template>

      <template v-slot:item.fax_number="{ item }">
        <div class="name">{{ item.fax_number ? item.fax_number : "-" }}</div>
      </template>

      <!-- Users -->

      <template v-slot:item.role="{ item }">
        <div
          class="role exact-center normal-user"
          :class="{
            'super-user': item.role.name === 'Super User',
          }"
        >
          {{ item.role ? item.role.name : "-" }}
        </div>
      </template>

      <template v-slot:item.user_status="{ item }">
        <div
          class="role exact-center inactive-user"
          :class="{
            'active-user': !item.archived,
          }"
        >
          {{ item.archived ? "Inactive" : "Active" }}
        </div>
      </template>

      <template v-slot:item.resetPassword="{ item }">
        <div>
          <img
            class="cursor-pointer"
            @click="onClickReset(item)"
            v-if="!item.isResetSelected"
            src="@/assets/icons/reset.svg"
          />
          <img
            class="cursor-pointer"
            @click="onClickReset(item)"
            v-else
            src="@/assets/icons/reset-active.svg"
          />
        </div>
      </template>

      <template v-slot:item.last_login="{ item }">
        <div class="name">
          {{ item.last_login ? formatDate(item.last_login) : "-" }}
        </div>
      </template>

      <template v-slot:item.last_session="{ item }">
        <div class="name">
          {{ item.last_session ? formatDate(item.last_session) : "-" }}
        </div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div class="name">
          {{ item.created_at ? formatDate(item.created_at) : "-" }}
        </div>
      </template>

      <!-- Orders -->

      <template v-slot:item.tracking_id="{ item }">
        <div class="name">{{ item.tracking_id ? item.tracking_id : "-" }}</div>
      </template>

      <template v-slot:item.order_no="{ item }">
        <div class="name">
          {{ item.orderdetail.orderId ? item.orderdetail.orderId : "-" }}
        </div>
      </template>

      <template v-slot:item.product_category="{ item }">
        <div class="name">
          {{ item.productdetails ? item.productdetails.product_category : "-" }}
        </div>
      </template>

      <template v-slot:item.product_code="{ item }">
        <div class="name">
          {{ item.productdetails ? item.productdetails.product_code : "-" }}
        </div>
      </template>

      <template v-slot:item.product_name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.productdetails.product_image"
            :url="`${serverUrl}/${item.productdetails.product_image}`"
            :name="item.productdetails.product_name"
            size="50"
            radius="12"
          />
          <Avatar
            v-else
            :name="item.productdetails.product_name"
            size="50"
            radius="12"
          />
          <div class="ml-2 name">
            {{ item.productdetails ? item.productdetails.product_name : "-" }}
          </div>
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <v-tooltip bottom max-width="500px">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="description ellipsis">
              {{
                item.productdetails
                  ? item.productdetails.product_description
                  : "-"
              }}
            </div>
          </template>
          <div>
            {{
              item.productdetails
                ? item.productdetails.product_description
                : "-"
            }}
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item.product_quantity="{ item }">
        <div class="name">
          {{ item.product_quantity ? item.product_quantity : "-" }}
        </div>
      </template>

      <template v-slot:item.uom="{ item }">
        <div class="name">{{ item.uom ? item.uom : "-" }}</div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div class="name">
          {{ item.created_at ? formatProductDate(item.created_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.expected_delivery_date="{ item }">
        <div class="name">
          {{
            item.expected_delivery_date
              ? formatProductDate(item.expected_delivery_date)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.price="{ item }">
        <div class="price">
          {{
            item.productdetails.currency
              ? currencyArray[item.productdetails.currency]
              : ""
          }}
          {{
            item.productdetails
              ? formatPrice(
                  item.productdetails.product_price,
                  item.product_quantity
                )
              : "-"
          }}
        </div>
      </template>
      <template v-slot:item.logistics="{ item }">
        <div
          class="logistic-badge exact-center logistic"
          v-if="item.logisticdetails"
        >
          {{ item.logisticdetails ? item.logisticdetails.company_name : "" }}
        </div>
        <div class="logistic-badge unassigned exact-center" v-else>
          Unassigned
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="name" :style="{ color: orderStatus[item.status] }">
          {{ item.status ? item.status : "-" }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="action-buttons display-flex align-center">
          <v-edit-dialog :return-value.sync="item.actions">
            <div class="more-options exact-center ml-2 cursor-pointer">
              <img src="@/assets/icons/more-options.svg" alt="more-options" />
            </div>
            <template v-slot:input>
              <InnerMoreOptions
                @editClicked="editClicked(item)"
                @deleteClicked="deleteClicked(item)"
                :isActiveInactive="selectedType === 'Users'"
                @activeToggle="activeClicked(item)"
                :active="!item.archived"
              />
            </template>
          </v-edit-dialog>
        </div>
      </template>
    </v-data-table>
    <AddWareHouse
      width="691"
      :item="selectedItem"
      :show="showWarehouse"
      @close="warehouseCloseClicked"
      :title="modalTitle"
      :key="warehouseModalKey"
      :isEdit="isWarehouseEdit"
      :company_id="item.id"
      @saveClicked="warehouseSaveClicked"
    />
    <AddUser
      width="928"
      :item="selectedItem"
      :show="showUser"
      :title="modalTitle"
      :key="userModalKey"
      :isEdit="isMerchantEdit"
      :company_id="item.id"
      @saveClicked="userSaveClicked"
      @close="userCloseClicked"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import AddWareHouse from "@/components/Admin/Merchant/AddWarehouse";
import AddUser from "@/components/Admin/Merchant/AddUser";
import {
  ORDER_STATUS,
  SERVER_URL,
  CURRENCY_SYMBOL,
} from "../../../../constants";

export default {
  name: "MerchantInnerTable",
  components: { AddWareHouse, AddUser },
  props: [
    "headers",
    "selectedType",
    "items",
    "totalItem",
    "item",
    "page",
    "searchValue",
    "isFilter",
    "keyValue",
    "hideFilter"
  ],
  data() {
    return {
      currencyArray: CURRENCY_SYMBOL,
      deleteDialog: false,
      orderStatus: [],
      showWarehouse: false,
      showUser: false,
      isMerchantEdit: false,
      isWarehouseEdit: false,
      modalTitle: "",
      selectedItem: {},
      warehouseModalKey: 0,
      userModalKey: 10,
      serverUrl: SERVER_URL,
      dateFilterType: [],
    };
  },
  computed: {
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      deleteMerchantUser: "admin/merchantUsers/deleteUser",
      setMerchantActiveUser: "admin/merchantUsers/setMerchantActiveUser",
      deleteMerchantWarehouse: "admin/warehouse/deleteWarehouse",
      getMerchantUserRoles: "admin/merchantUsers/getMerchantUserRoles",
      exportOrder: "admin/orders/exportOrder",
    }),
    async onExportOrder() {
      await this.exportOrder(this.item?.id);
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY | h:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    formatPrice(price, quantity) {
      const total = price * quantity;
      return total;
    },
    onSearch(value) {
      this.$emit("onSearch", {
        value,
        type: this.selectedType,
        merchant: this.item,
      });
    },
    filterClicked(value) {
      this.$emit("filterClicked", {
        value,
        type: this.selectedType,
        merchant: this.item,
        isFilter: true,
      });
    },
    resetClicked() {
      this.$emit("filterClicked", {
        value: {},
        type: this.selectedType,
        merchant: this.item,
        isFilter: false,
      });
    },
    onAddWareHouse() {
      this.modalTitle = "Add Warehouse";
      this.showWarehouse = true;
      this.warehouseModalKey += 1;
    },
    onAddUsers() {
      this.modalTitle = "Add User";
      this.showUser = true;
      this.userModalKey += 1;
    },
    onClickReset(item) {
      this.usersItems.map((obj) => {
        if (obj.id === item.id) obj.isResetSelected = !obj.isResetSelected;
        else obj.isResetSelected = false;
      });
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    async activeClicked(item) {
      try {
        item.archived
          ? await this.setMerchantActiveUser(item)
          : await this.deleteMerchantUser(item);
        this.$emit("refresh", {
          type: this.selectedType,
          merchant: this.item,
        });
      } catch (error) {
        console.log(error);
      }
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      if (this.selectedType === "Users") {
        try {
          await this.deleteMerchantUser(this.selectedItem);
          this.$emit("refresh", {
            type: this.selectedType,
            merchant: this.item,
          });
          this.deleteDialog = false;
        } catch (err) {
          console.log(err);
          this.deleteDialog = false;
        }
      } else if (this.selectedType === "Warehouse") {
        try {
          await this.deleteMerchantWarehouse(this.selectedItem);
          this.$emit("refresh", {
            type: this.selectedType,
            merchant: this.item,
          });
          this.deleteDialog = false;
        } catch (err) {
          console.log(err);
          this.deleteDialog = false;
        }
      }
    },
    userCloseClicked() {
      this.isMerchantEdit = false;
      this.selectedItem = {};
      this.showUser = false;
    },
    warehouseCloseClicked() {
      this.isWarehouseEdit = false;
      this.selectedItem = {};
      this.showWarehouse = false;
    },
    userSaveClicked() {
      this.$emit("refresh", {
        type: this.selectedType,
        merchant: this.item,
      });
    },
    warehouseSaveClicked() {
      this.$emit("refresh", {
        type: this.selectedType,
        merchant: this.item,
      });
    },
    editClicked(item) {
      if (this.selectedType === "Warehouse") {
        this.modalTitle = "Edit Warehouse";
        this.showWarehouse = true;
        this.selectedItem = item;
        this.isWarehouseEdit = true;
        this.warehouseModalKey += 1;
      } else if (this.selectedType === "Users") {
        this.modalTitle = "Edit User";
        this.isMerchantEdit = true;
        this.selectedItem = item;
        this.showUser = true;
        this.userModalKey += 1;
      }
    },
    updatePagination(value) {
      const type = this.selectedType;
      const page = value;
      this.$emit("updatePagination", { type, page, merchant: this.item });
    },
    async getAllUserRoles() {
      try {
        await this.getMerchantUserRoles(this.item);
      } catch (err) {
        console.log(err);
      }
    },
    setDateFilterType() {
      this.dateFilterType =
        this.selectedType === "Warehouse"
          ? [
              {
                name: "Created Date",
                value: "created_at",
              },
            ]
          : this.selectedType === "Users"
          ? [
              {
                name: "Created Date",
                value: "created_at",
              },
              {
                name: "Last Login Date",
                value: "last_login",
              },
            ]
          : [
              {
                name: "Order Created",
                value: "created_at",
              },
              {
                name: "Order Delivered",
                value: "order_delivered",
              },
              {
                name: "Order Pending",
                value: "order_pending",
              },
            ]; // --> For Orders
    },
  },
  async created() {
    this.setDateFilterType();
    this.orderStatus = ORDER_STATUS;
    await this.getAllUserRoles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.merchant-inner-table-container {
  ::v-deep .v-data-footer__select {
    display: none;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }

  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 1.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .super-user {
    background: #ffede3 !important;
    color: #e87d1b !important;
  }
  .normal-user {
    background: #eeefff;
    color: #1b25c6;
  }
  .product-img {
    width: 50px;
    height: 50px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px 6px;
    img {
      width: 100%;
    }
  }
  .description {
    color: $secondary-color;
    font-weight: 600;
    font-size: 12px;
  }
  .price {
    color: #39c25f;
    font-weight: 600;
    font-size: 14px;
  }
  .logistic-badge {
    width: 160px;
    padding: 10px;
    border-radius: 6px;
  }
  .unassigned {
    background: #ffeddf;
    font-weight: 600;
    font-size: 12px;
  }
  .logistic {
    background: rgba(212, 5, 17, 0.1);
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
