var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.tableKey,staticClass:"user-table-container pl-7 pr-7 width-100"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete User","cancel":"Cancel","content":"Are you sure you want to delete the selected User? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalUsers === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.userHeaders,"items":_vm.allUsers,"item-key":"id","server-items-length":_vm.totalUsers,"options":{ page: _vm.page, itemsPerPage: _vm.limit },"hide-default-footer":"","show-expand":""},on:{"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.user_img)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.user_img)),"name":item.user_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.user_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.user_name ? item.user_name : "-")+" ")])],1)]}},{key:"item.resetPassword",fn:function(ref){
var item = ref.item;
return [_c('div',[(!item.isResetSelected)?_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/icons/reset.svg")},on:{"click":function($event){return _vm.onClickReset(item)}}}):_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/icons/reset-active.svg")},on:{"click":function($event){return _vm.onClickReset(item)}}})])]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : "-")+" ")])]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.last_login ? _vm.formatDate(item.last_login) : "-")+" "),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.last_login ? _vm.formatTime(item.last_login) : "")+" ")])])])]}},{key:"item.last_session",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.last_session ? _vm.formatDate(item.last_session) : "-")+" "),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.last_session ? _vm.formatTime(item.last_session) : "")+" ")])])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" "),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatTime(item.created_at)))])])])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"role exact-center normal-user",class:{
            'super-user': item.role.name === 'Super User',
          }},[_vm._v(" "+_vm._s(item.role && item.role.name ? item.role.name : "-")+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"role exact-center inactive-user",class:{
            'active-user': !item.archived,
          }},[_vm._v(" "+_vm._s(item.archived ? "Inactive" : "Active")+" ")])]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{attrs:{"isActiveInactive":true,"active":!props.item.archived},on:{"editClicked":function($event){return _vm.editClicked(props.item)},"deleteClicked":function($event){return _vm.deleteClicked(props.item)},"activeToggle":function($event){return _vm.activeClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1)]}}])})],1),_c('AddUser',{key:_vm.addUserKey,attrs:{"width":"928","show":_vm.showUser,"title":"Edit User","item":_vm.selectedItem,"isEdit":true},on:{"close":function($event){_vm.showUser = false},"saveClicked":_vm.saveClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }