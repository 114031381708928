<template>
  <div class="settings-container mt-4">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Role`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected role ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <div class="header justify-space-between align-center pl-6 pr-6">
      <h1>Roles</h1>
      <div
        class="exact-center"
        v-if="loggedUserPermission.edit_roles_and_permissions"
      >
        <v-text-field
          v-model="roleName"
          class="role-name-field mb-1 pl-1"
          placeholder="Role Name"
        />
        <Button
          label="Create Role"
          type="primary"
          width="106px"
          height="36px"
          @click="saveRole"
          :disabled="!roleName"
          v-if="!isUpdate"
        />
        <Button
          v-else
          label="Save"
          type="primary"
          width="106px"
          height="36px"
          @click="updateRole"
          :disabled="!roleName"
        />
      </div>
    </div>
    <div
      class="p-4 role-table"
      v-if="loggedUserPermission.edit_roles_and_permissions"
    >
      <v-data-table
        :headers="roleHaders"
        :items="adminRoles"
        item-key="id"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        hide-default-header
        :key="tableKey"
      >
        <template v-slot:item.name="props">
          <div class="roles-layout display-flex justify-space-between">
            <div class="role">
              <img
                v-if="props.item.expandSelected"
                @click="onClickExpand(props)"
                class="mr-3 cursor-pointer"
                src="@/assets/icons/down-arrow.svg"
              />
              <img
                v-else
                @click="onClickExpand(props)"
                class="mr-3 cursor-pointer"
                src="@/assets/icons/right-arrow.svg"
              />
              {{ props.item.name ? props.item.name : "-" }}
            </div>
            <!-- <img
              @click="deleteClicked"
              class="cursor-pointer"
              src="@/assets/icons/delete.svg"
            /> -->
          </div>
        </template>

        <!-- Dashboard -->
        <template
          v-if="adminRolePermision && adminRolePermision.length"
          v-slot:expanded-item="{ headers }"
        >
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <div class="permission-section">
              <h1 class="permission-title">Dashboard</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[0].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[0])"
                    ></v-switch>
                  </div>
                  <h1>View Dashboard</h1>
                </div>
              </div>
            </div>

            <!-- Logistics -->
            <div class="permission-section">
              <h1 class="permission-title">Logistics</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[1].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[1])"
                    ></v-switch>
                  </div>
                  <h1>View Logistics</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[2].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[2])"
                    ></v-switch>
                  </div>
                  <h1>Edit Logistics</h1>
                </div>
              </div>
            </div>

            <!-- Customer -->
            <div class="permission-section">
              <h1 class="permission-title">Customer</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[3].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[3])"
                    ></v-switch>
                  </div>
                  <h1>View Customer</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[4].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[4])"
                    ></v-switch>
                  </div>
                  <h1>Edit Customer</h1>
                </div>
              </div>
            </div>

            <!-- Users -->
            <div class="permission-section">
              <h1 class="permission-title">Users</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[5].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[5])"
                    ></v-switch>
                  </div>
                  <h1>View Users</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[6].allow"
                      @change="changePermission($event, adminRolePermision[6])"
                      color="#FF003F"
                      class="mt-1"
                    ></v-switch>
                  </div>
                  <h1>Edit Users</h1>
                </div>
              </div>
            </div>

            <!-- Users -->
            <div class="permission-section">
              <h1 class="permission-title">Merchants</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[10].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[10])"
                    ></v-switch>
                  </div>
                  <h1>View Merchants</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[11].allow"
                      @change="changePermission($event, adminRolePermision[11])"
                      color="#FF003F"
                      class="mt-1"
                    ></v-switch>
                  </div>
                  <h1>Edit Merchants</h1>
                </div>
              </div>
            </div>

            <!-- Promotions -->
            <div class="permission-section">
              <h1 class="permission-title">Promotions</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[12].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[12])"
                    ></v-switch>
                  </div>
                  <h1>View Promotions</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[13].allow"
                      @change="changePermission($event, adminRolePermision[13])"
                      color="#FF003F"
                      class="mt-1"
                    ></v-switch>
                  </div>
                  <h1>Edit Promotions</h1>
                </div>
              </div>
            </div>

            <!-- Settings -->
            <div class="permission-section">
              <h1 class="permission-title">Settings</h1>
              <div class="permssion-options-field-container">
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      @change="changePermission($event, adminRolePermision[7])"
                      color="#FF003F"
                      class="mt-1"
                      v-model="adminRolePermision[7].allow"
                    ></v-switch>
                  </div>
                  <h1>View/Edit Account</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      @change="changePermission($event, adminRolePermision[8])"
                      color="#FF003F"
                      class="mt-1"
                      v-model="adminRolePermision[8].allow"
                    ></v-switch>
                  </div>
                  <h1>View/Edit Security</h1>
                </div>
                <div class="permssion-options-field">
                  <div class="mr-2">
                    <v-switch
                      v-model="adminRolePermision[9].allow"
                      color="#FF003F"
                      class="mt-1"
                      @change="changePermission($event, adminRolePermision[9])"
                    ></v-switch>
                  </div>
                  <h1>Edit Roles and permissons</h1>
                </div>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Roles",
  data() {
    return {
      roleName: "",
      tableKey: 0,
      isUpdate: false,
      roleId: "",
      deleteDialog: false,
      singleExpand: true,
      expanded: [],
      roleHaders: [
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "name",
          width: "500px",
        },
        // { text: "", value: "data-table-expand", width: '100px' },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchAdminRoles: "admin/settings/fetchAdminRoles",
      createNewAdminRole: "admin/settings/createNewAdminRole",
      deleteAdminRole: "admin/settings/deleteAdminRole",
      updateAdminRole: "admin/settings/updateAdminRole",
      fetchAdminRoleById: "admin/settings/fetchAdminRoleById",
      updatePermssion: "admin/settings/updatePermssion",
    }),
    async saveRole() {
      await this.createNewAdminRole({ name: this.roleName });
      await this.refreshTable();
    },
    async updateRole() {
      await this.updateAdminRole({ name: this.roleName, id: this.roleId });
      await this.refreshTable();
    },
    async refreshTable() {
      this.roleName = "";
      this.roleId = "";
      this.isUpdate = false;
      await this.fetchAdminRoles();
      this.tableKey += 1;
      this.expanded = [];
    },
    onClickExpand(slotData) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1);
        this.adminRoles.map((obj) => {
          obj.expandSelected = false;
        });
        this.roleName = "";
        this.isUpdate = false;
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.adminRoles.map(async (obj) => {
          if (obj.id === slotData.item.id) {
            obj.expandSelected = true;
            this.isUpdate = true;
            this.roleName = slotData.item.name;
            this.roleId = slotData.item.id;
            await this.fetchAdminRoleById(obj.id);
          } else obj.expandSelected = false;
        });
      }
    },
    deleteClicked() {
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    onDeleteConfirm() {
      this.deleteDialog = false;
      this.deleteAdminRole();
    },
    async changePermission(value, permission) {
      await this.updatePermssion(permission);
    },
  },
  async created() {
    await this.fetchAdminRoles();
  },
  computed: {
    ...mapGetters("admin/settings", ["adminRoles", "adminRolePermision"]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
.role-name-field {
  width: 200px;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background: #ffffff !important;
  box-shadow: unset !important;
}
.inner-table-td {
  background: $white-color !important;
}
.settings-container {
  height: 963px;
  overflow-y: scroll;
  background: $white-color;
  border: 1px solid #e5e5e5;
  .header {
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    h1 {
      font-weight: 500;
      font-size: 1.25rem;
      color: $secondary-color;
    }
  }
}
::v-deep .v-input__control > .v-input__slot {
  min-height: 34px !important;
  background: #f9f9f9 !important;
  border-radius: 4px !important;
  border-bottom: unset !important;
  border: none !important;
}
::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: #f9f9f9 !important;
  border-radius: 4px !important;
  border: unset !important;
}
::v-deep .v-text-field__details {
  display: none;
}
::v-deep .v-input__slot:after {
  border-style: none !important;
}
::v-deep .v-input__slot::before {
  border-style: none !important;
}
.role-table {
  .roles-layout {
    width: 100%;
  }
  .role {
    color: #4a5571;
    font-weight: 600;
    font-size: 14px;
  }
}
.permission-section {
  border-bottom: 1px solid rgba(13, 24, 66, 0.1);
  margin-top: 20px;
  padding-bottom: 20px;
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0d1842;
  }
  .permssion-options-field-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .permssion-options-field {
      display: flex;
      align-items: center;
      min-width: 33.33%;
      h1 {
        color: #0d1842;
        font-size: 14px;
      }
    }
  }
  .permission-title {
    color: #0d1842;
    font-size: 14px;
  }
  ::v-deep .v-messages {
    display: none !important;
  }
  ::v-deep .v-input--switch__thumb {
    height: 13px;
    width: 13px;
    top: calc(50% - 7px);
    left: 3px;
  }
  ::v-deep .v-input--selection-controls__ripple {
    display: none !important;
  }
}
</style>
