<template>
  <div>
    <CommonToolbar
      title="Customers"
      :secondaryButtonLabel="loggedUserPermission.view_customers && 'Export'"
      :isSearch="loggedUserPermission.view_customers"
      @search="onSearch"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_customers"
    />
    <TableSection v-if="loggedUserPermission.view_customers" :key="tableKey" />
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableSection from "./TableSection.vue";
export default {
  name: "AdminCustomers",
  components: {
    TableSection,
  },
  data() {
    return {
      tableKey: 0,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
        {
          name: "Last Login Date",
          value: "last_login",
        },
        {
          name: "Last Ordered Date",
          value: "last_ordered",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("admin/customers", ["fromDate", "toDate"]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      setSearchQuery: "admin/customers/setSearchQuery",
      setDateRange: "admin/customers/setDateRange",
      exportCustomers: "admin/customers/exportCustomers",
      setPage: "admin/customers/setPage",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportCustomers();
    },
  },
  created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    this.tableKey += 1;
  },
};
</script>

<style lang="scss" scoped></style>
