<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="picker-label display-flex align-center cursor-pointer position-relative"
          v-bind="attrs"
          v-on="on"
          v-if="!month && !year"
        >
          <div v-if="!report">Select Month & Year</div>
          <img
            src="@/assets/icons/calendar-dark.svg"
            class="ml-2"
            alt="calendar"
          />
          <div
            v-if="month && year"
            class="filter-marker position-absolute"
          ></div>
        </div>
        <div
          v-else
          class="picker-label display-flex align-center cursor-pointer position-relative"
          v-bind="attrs"
          v-on="on"
        >
          <div v-if="!report">{{ month }}, {{ year }}</div>
          <img
            src="@/assets/icons/calendar-dark.svg"
            class="ml-2"
            alt="calendar"
          />
          <div
            v-if="month && year"
            class="filter-marker position-absolute"
          ></div>
        </div>
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        :show-current="start"
        @change="save"
        color="#ff003f"
        type="month"
      >
        <v-spacer></v-spacer>
        <v-btn class="mb-4" text color="primary" @click="resetClicked">
          Reset
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "MonthPicker",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
      description: "Defines the label of the textfield to be displayed",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
      description: "Defines the placeholder of the textfield",
    },
    month: {
      type: String,
      required: false,
      default: "",
      description: "Defines the placeholder of the textfield",
    },
    year: {
      type: String,
      required: false,
      default: "",
      description: "Defines the placeholder of the textfield",
    },
    icon: {
      type: String,
      required: false,
      default: "calendar",
      description: "Defines the icon of the textfield",
    },
    dateValue: {
      type: String,
      required: false,
      default: "",
      description: "Defines the value of the textfield",
    },
    minDate: {
      type: String,
      required: false,
      default: "1950-01-01",
      description: "Defines the value of min date",
    },
    maxDate: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
      description: "Defines the value of max date",
    },
    startDate: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
      description: "Defines the value of start date",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    report: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    date: null,
    menu: false,
    start: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    // menu(val) {
    //   if (val) {
    //     this.$refs.picker.activePicker = "YEAR";
    //   }
    // },
    date() {
      this.$emit("change", this.date);
    },
  },
  methods: {
    save(date) {
      this.$emit("change", date);
      this.$refs.menu.save(date);
    },
    resetClicked() {
      this.$emit("reset");
    },
  },
  mounted() {
    if (this.dateValue) {
      this.date = this.dateValue;
      this.start = this.dateValue;
    } else {
      this.start = this.startDate;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common";
.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -3px;
  right: -4px;
}
::v-deep .v-picker__title__btn {
  opacity: unset !important;
}
::v-deep .v-picker {
  border: 1px solid #dce2f0 !important;
}
::v-deep .v-btn__content {
  font-weight: 500;
  font-size: 12px;
}
::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  box-sizing: border-box;
  border-radius: 1px;
  height: 64px;
}

::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-text-field input {
  margin-left: 6px !important;
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
