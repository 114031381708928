<template>
  <div class="promotion-table-container pl-7 pr-7 width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Promotion`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Promotion ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="promotionsCount === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="promotionHeaders"
        :items="promotions"
        item-key="id"
        hide-default-footer
        @update:page="updatePagination($event)"
        :server-items-length="promotionsCount"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:item.productdetails="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.promotion_img"
              :url="`${serverUrl}/${item.promotion_img}`"
              :name="item.productdetails.name"
              size="50"
              radius="12"
            />
            <Avatar
              v-else
              :name="item.productdetails.name"
              size="50"
              radius="12"
            />
            <div class="name ml-4">
              {{ item.productdetails.name ? item.productdetails.name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.product_description="{ item }">
          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="name ellipsis">
                {{ item.product_description ? item.product_description : "-" }}
              </div>
            </template>
            <div>
              {{ item.product_description ? item.product_description : "-" }}
            </div>
          </v-tooltip>
        </template>

        <template v-slot:item.link="{ item }">
          <a
            :href="formatWebsite(item.link)"
            class="website cursor-pointer"
            target="_blank"
            v-if="item.link"
          >
            {{ item.link }}
          </a>
          <div class="no-website" v-else>Not Available</div>
        </template>

        <template v-slot:item.createdby="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.createdby && item.createdby.profile_pic"
              :url="`${serverUrl}/${item.createdby.profile_pic}`"
              :name="item.createdby.name"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.createdby.name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.createdby.name ? item.createdby.name : "-" }}
            </div>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>
            <div class="name display-flex flex-col">
              {{ formatDate(item.created_at) }}
              <div class="time">{{ formatTime(item.created_at) }}</div>
            </div>
          </div>
        </template>
        <template v-slot:item.end_date="{ item }">
          <div>
            <div class="name display-flex flex-col">
              {{ item.end_date ? formatDate(item.end_date) : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.customer_id="{ item }">
          <div class="action-buttons display-flex align-center">
            <ActionButton
              label="Customers"
              :count="item.promotioncount ? item.promotioncount : 0"
              icon="customer"
              width="124px"
              class="mr-4"
              @click="customerClikced(item)"
            />
          </div>
        </template>
        -->
        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <AddPromotion
      :width="928"
      :show="showEdit"
      @close="showEdit = false"
      title="Edit Promotion"
      :isEdit="true"
      :item="selectedItem"
      :key="editKey"
      @saveClicked="refresh"
    />
    <CustomerListModal
      :width="461"
      :show="showCustomer"
      @close="customerClosed"
      title="Customers"
    />
  </div>
</template>

<script>
import moment from "moment";
import AddPromotion from "./AddPromotion.vue";
import CustomerListModal from "./CustomerListModal.vue";
import { mapGetters, mapActions } from "vuex";
import { SERVER_URL, ITEMS_PER_PAGE, HTTP_REGEX } from "../../../../constants";

export default {
  name: "AdminPromotionTable",
  components: { AddPromotion, CustomerListModal },
  data() {
    return {
      serverUrl: SERVER_URL,
      itemsPerPage: ITEMS_PER_PAGE,
      isLoading: false,
      expanded: [],
      deleteDialog: false,
      promotionHeaders: [
        {
          text: "Product Name",
          align: "start",
          sortable: false,
          value: "productdetails",
          width: "280px",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "product_description",
          width: "290px",
        },
        {
          text: "Link",
          align: "start",
          sortable: false,
          value: "link",
          width: "150px",
        },
        {
          text: "Customers",
          align: "start",
          sortable: false,
          value: "customer_id",
          width: "220px",
        },
        {
          text: "Created By",
          align: "start",
          sortable: false,
          value: "createdby",
          width: "240px",
        },
        {
          text: "Created Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "150px",
        },
        {
          text: "End Date",
          align: "start",
          sortable: false,
          value: "end_date",
          width: "150px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          width: "150px",
        },
      ],
      showEdit: false,
      showCustomer: false,
      selectedItem: {},
      editKey: 0,
    };
  },
  methods: {
    ...mapActions({
      getPromotions: "admin/promotion/getPromotions",
      setPage: "admin/promotion/setPage",
      setLimit: "admin/promotion/setLimit",
      deletePromotions: "admin/promotion/deletePromotions",
      fetchCustmerDetails: "admin/promotion/fetchCustmerDetails",
    }),
    formatWebsite(url) {
      if (!HTTP_REGEX.test(url)) {
        url = "http://" + url;
      }
      return url;
    },
    async customerClikced(item) {
      await this.fetchCustmerDetails(item.id);
      this.showCustomer = true;
    },
    customerClosed() {
      this.showCustomer = false;
    },
    editClicked(item) {
      this.selectedItem = item;
      this.editKey += 1;
      this.showEdit = true;
    },
    deleteClicked(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
      this.selectedItem = {};
    },
    async onDeleteConfirm() {
      try {
        await this.deletePromotions(this.selectedItem);
        await this.getPromotions();
        this.selectedItem = {};
        this.deleteDialog = false;
      } catch (error) {
        console.log(error);
        this.deleteDialog = false;
      }
    },
    formatDate(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    formatTime(time) {
      return moment(time).format("LT");
    },
    async updatePagination(value) {
      this.setPage(value);
      await this.refresh();
    },
    async updatePerPage(value) {
      this.setLimit(value);
      await this.refresh();
    },
    async refresh() {
      this.showEdit = false;
      this.isLoading = true;
      await this.getPromotions();
      this.isLoading = false;
    },
  },
  async created() {
    if (!this.loggedUserPermission.edit_promotions) {
      this.promotionHeaders.splice(this.promotionHeaders.length - 1, 1);
    }
    await this.refresh();
  },
  computed: {
    ...mapGetters("admin/promotion", [
      "promotions",
      "promotionsCount",
      "page",
      "limit",
    ]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .v-icon {
  display: none !important;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}

.promotion-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .time {
    font-weight: 600;
    font-size: 0.875rem;
    color: #b5b5c3;
  }
  .website {
    color: #0047ff;
    font-weight: 600;
    font-size: 0.875rem;
  }
  .no-website {
    color: #4a557180;
    font-weight: 600;
    font-size: 0.875rem;
  }
}
</style>
