<template>
  <div class="report-outer p-4 mt-3">
    <div class="header-container align-center justify-space-between mt-2">
      <div class="display-flex align-center mb-2">
        <h1>Report</h1>
        <MonthPicker
          :key="monthPickerKey"
          :month="monthText"
          :year="year"
          @change="monthPicked"
          @reset="resetClicked"
          :report="true"
        />
      </div>
      <div class="header-slot display-flex align-center">
        <img
          @click="exportClicked"
          class="mr-2 mb-2 cursor-pointer"
          src="@/assets/icons/export.svg"
          alt="export"
        />
        <div class="dropdown-width">
          <v-autocomplete
            @change="getOption"
            :items="options"
            v-model="option"
          />
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" size="30" class="spinner-class exact-center" />
    <div v-else>
      <div class="pt-3" v-for="(item, i) in reportCompanies" :key="i">
        <div class="justify-space-between align-center m-3">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.company_img"
              :url="`${serverUrl}/${item.company_img}`"
              :name="item.company_name"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.company_name" size="50" radius="12" />
            <div class="ml-4">
              <div class="name">
                {{ item.company_name }}
                <img
                  class="mb-2 cursor-pointer"
                  src="@/assets/icons/export.svg"
                  alt="export"
                  @click="exportCompany(item)"
                  width="20px"
                  height="20px"
                />
              </div>
              <div class="count">{{ item.type }}</div>
            </div>
          </div>
          <div>
            <div class="name">{{ item.customercount }}</div>
            <div class="count">Customers</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <v-pagination
        v-model="page"
        :length="total"
        :total-visible="6"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL } from "../../../../constants";
import MonthPicker from "./MonthPicker.vue";

export default {
  name: "Report",
  components: { MonthPicker },
  data() {
    return {
      serverUrl: SERVER_URL,
      options: ["Merchant", "Logistic"],
      option: "Merchant",
      page: 1,
      total: 0,
      isLoading: false,
      monthText: "",
      month: "",
      year: "",
      monthPickerKey: 10,
    };
  },
  computed: {
    ...mapGetters("admin/dashboard", ["reportCompanies", "reportCompanyCount"]),
  },
  methods: {
    ...mapActions({
      fetchMerchantCustomerReport:
        "admin/dashboard/fetchMerchantCustomerReport",
      setReportsPage: "admin/dashboard/setReportsPage",
      fetchLogisticsCustomerReport:
        "admin/dashboard/fetchLogisticsCustomerReport",
      exportMerchantById: "admin/dashboard/exportMerchantById",
      exportLogisticById: "admin/dashboard/exportLogisticById",
      exportReport: "admin/dashboard/exportReport",
    }),
    async exportCompany(company) {
      this.option === "Merchant"
        ? await this.exportMerchantById(company?.id)
        : await this.exportLogisticById(company?.id);
    },
    async getOption(value) {
      this.option = value;
      this.setReportsPage(1);
      await this.fetchReport();
    },
    async resetClicked() {
      this.month = "";
      this.year = "";
      this.monthText = "";
      this.monthPickerKey += 1;
      await this.fetchReport();
    },
    async monthPicked(month) {
      this.month = moment(month).format("M");
      this.monthText = moment(month).format("MMM");
      this.year = moment(month).format("YYYY");
      await this.fetchReport();
    },
    async exportClicked() {
      await this.exportReport({
        month: this.month,
        year: this.year,
        type: this.option,
      });
    },
    async fetchReport() {
      this.isLoading = true;
      if (this.option === "Merchant")
        await this.fetchMerchantCustomerReport({
          month: this.month,
          year: this.year,
        });
      else
        await this.fetchLogisticsCustomerReport({
          month: this.month,
          year: this.year,
        });
      this.total = Math.ceil(this.reportCompanyCount / 5);
      this.isLoading = false;
    },
  },
  watch: {
    async page() {
      this.setReportsPage(this.page);
      await this.fetchReport();
    },
  },
  async created() {
    this.setReportsPage(1);
    await this.fetchReport();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.report-outer {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  height: 650px;
  overflow-y: scroll;
  .header-container {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
      color: #000000;
    }
  }
  .spinner-class {
    height: 500px;
  }
  .name {
    color: #4a5571;
    font-weight: 600;
    font-size: 14px;
  }
  .count {
    color: #b5b5c3;
    font-size: 13px;
    font-weight: 500;
  }
  ::v-deep .v-pagination__item {
    height: 24px;
    min-width: 24px;
    font-size: 0.8rem;
  }
  ::v-deep .v-pagination__navigation {
    height: 24px;
    min-width: 24px;
  }
}
.header-slot {
  margin-top: -5px;
  color: rgba(13, 24, 66, 0.5);
  font-size: 12px;
  .dropdown-width {
    width: 100px;
  }
  ::v-deep .v-input__slot::before {
    border-style: none !important;
  }
  ::v-deep .v-text-field {
    padding-top: unset !important;
    margin-top: unset !important;
  }
  ::v-deep .v-select__selection {
    color: rgba(13, 24, 66, 0.8) !important;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__slot:after {
    border-style: none !important;
  }
}
</style>
