<template>
  <div class="filter-outer-container">
    <div
      class="add-user-title ml-6 pt-8 display-flex width-100 align-center justify-space-between"
    >
      Sort
    </div>
    <div class="sub-title mb-3 mt-4  pl-6">Orders</div>
    <v-row class="no-gutters pl-6 pr-6">
      <v-col>
        <v-row no-gutters>
          <v-col>
            <SelectField
              label="Sort Type"
              :value="formData.sortType"
              :data-set="sortFilterDataSet"
              @change="getFormData($event, 'sortType')"
            />
          </v-col>
          <v-col class="ml-3">
            <DateSelectField
              label="Date Range"
              :isDatePicker="true"
              :data-set="dateCreatedDataSet"
              @change="getFormData($event, 'dateRange')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- footer -->
    <v-row class="justify-end footer-section pb-5 mt-4">
      <div class="justify-end">
        <Button label="Reset" type="cancel" width="94px" @click="onCancel" />
        <Button
          class="ml-3"
          label="Filter"
          type="save"
          width="94px"
          @click="onFilterClicked"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";

export default {
  name: "MerchantFilter",
  mixins: [validationMixin],
  validations: {
    formData: {},
  },
  props: [],
  data() {
    return {
      isLoading: false,
      markers: [],
      formData: {
        dateRange: "",
        sortType: "",
      },
      dateCreatedDataSet: ["Today", "This Week", "This Month"],
      sortFilterDataSet: [
        { name: "Most Orders", key: "Most order" },
        { name: "Least Orders", key: "Least order" },
        { name: "Most Customers", key: "Most customer" },
        { name: "Least Customers", key: "Least customer" },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions({ setFilterValues: "admin/merchants/setFilterValues" }),
    onCancel() {
      this.setFilterValues(null);
      this.$emit("onClose");
    },
    async onFilterClicked() {
      await this.setFilterValues({type: this.formData.sortType ? 'sorted' : '',...this.formData});
      this.$emit("onFilter");
    },
    async getFormData(value, fieldName) {
      if(fieldName === 'sortType'){
        this.formData[fieldName] = value.key;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.sub-title {
  font-weight: 500;
  font-size: 13px;
  color: #4a5571;
}
.filter-outer-container {
  width: 780px;
  min-height: 200px;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
