<template>
  <div class="tracking-card-container">
    <div class="map-container position-relative">
      <div class="position-absolute display-flex align-center width-100">
        <div class="search-container align-center header-slot">
          <img
            class="search-icon"
            width="16"
            height="16"
            src="@/assets/icons/search.svg"
          />
          <v-text-field
            class="search-input"
            placeholder="Tracking ID"
            :value="searchComputedValue"
            @input="changeSearch"
          />
        </div>
      </div>
      <GmapMap
        :center="center"
        :zoom="12"
        map-type-id="roadmap"
        style="height: 518px"
        ref="map"
        :key="mapKey"
      >
        <DirectionsRenderer
          travelMode="DRIVING"
          :origin="start"
          :destination="destination"
          v-if="this.track"
        />
        <GmapPolyline
          v-if="curvedPath && curvedLine"
          :path="curvedPath"
          :options="options"
        />
        <!-- Start Marker -->
        <GmapMarker
          :position="start"
          :clickable="true"
          :draggable="false"
          @click="center = start"
          :icon="{ url: require('@/assets/icons/map-marker.svg') }"
        />
        <!--Destination Marker -->
        <GmapMarker
          :position="destination"
          :clickable="true"
          :draggable="false"
          :icon="{ url: require('@/assets/icons/destination.svg') }"
        />
        <!-- Destination Big Circle -->
        <GmapCircle
          :center="destination"
          :radius="100"
          :visible="true"
          :options="{
            fillColor: circleMarker2,
            fillOpacity: 0.2,
            strokeColor: 'transparent',
          }"
        >
          <!-- Destination Small Circle -->
        </GmapCircle>
        <GmapCircle
          :center="destination"
          :radius="50"
          :visible="true"
          :options="{
            fillColor: circleMarker2,
            fillOpacity: 0.5,
            strokeColor: 'transparent',
          }"
        >
        </GmapCircle>
      </GmapMap>
    </div>
    <Timeline :key="timelineKey" :order="orderDetails" :log="orderLogs" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import { range } from "lodash";
import Timeline from "@/components/Admin/Dashboard/Timeline";
import DirectionsRenderer from "../../common/DirectionsRenderer";

export default {
  name: "TrackingCard",
  components: { Timeline, DirectionsRenderer },
  data() {
    return {
      searchValue: "",
      circleMarker1: "#FF003F",
      circleMarker2: "#FF003F",
      center: { lat: 1.3521, lng: 103.8198 },
      start: { lat: 0, lng: 0 },
      destination: { lat: 0, lng: 0 },
      mapKey: 10,
      timelineKey: 20,
      timeInterval: "",
      track: false,
      curvedLine: false,
      options: {
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      getOrderTrackingData: "admin/dashboard/getOrderTrackingData",
      trackOrder: "admin/dashboard/trackOrder",
      setOrderTrackingDataEmpty: "admin/dashboard/setOrderTrackingDataEmpty",
    }),
    changeSearch: debounce(async function(searchTerm) {
      this.center = { lat: 1.3521, lng: 103.8198 };
      this.start = {};
      this.destination = {};
      this.track = false;
      this.curvedLine = true;
      clearTimeout(this.timeInterval);
      this.searchValue = searchTerm;
      await this.getOrderTrackingData(searchTerm.toUpperCase());
      this.timelineKey += 1;
      await this.trackOrder(searchTerm.toUpperCase());
      this.center = this.orderTrackDetails.fromAddressDetails
        ? {
            lat: this.orderTrackDetails.fromAddressDetails.latitude,
            lng: this.orderTrackDetails.fromAddressDetails.longitude,
          }
        : {};
      this.start = this.orderTrackDetails.fromAddressDetails
        ? {
            lat: this.orderTrackDetails.fromAddressDetails.latitude,
            lng: this.orderTrackDetails.fromAddressDetails.longitude,
          }
        : {};
      this.destination = this.orderTrackDetails.toAddressDetails
        ? {
            lat: this.orderTrackDetails.toAddressDetails.latitude,
            lng: this.orderTrackDetails.toAddressDetails.longitude,
          }
        : {};
      if (
        this.orderTrackDetails.status === "Order out to delivery" ||
        this.orderTrackDetails.status ===
          "Parcel picked by logistics to Customer"
      ) {
        this.curvedLine = false;
        this.track = true;
        this.liveOrderTrack(searchTerm.toUpperCase());
      }
    }, 800),
    orderTrack() {
      this.start = {
        lat: this.orderTrackDetails.fromAddressDetails.latitude,
        lng: this.orderTrackDetails.fromAddressDetails.longitude,
      };
      this.directionKey += 1;
      // this.center = this.start;
    },
    async liveOrderTrack(searchTerm) {
      let that = this;
      await this.trackOrder(searchTerm);
      this.orderTrack();
      this.timeInterval = setTimeout(function() {
        that.liveOrderTrack(searchTerm);
      }, 3000);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("admin/dashboard", [
      "orderDetails",
      "orderLogs",
      "orderTrackDetails",
    ]),
    searchComputedValue() {
      return this.searchValue.toUpperCase();
    },

    curvedPath() {
      if (this.start && this.destination) {
        return range(100).map((i) => {
          const tick = i / 99;
          /* Bezier curve -- set up the control points */
          const dlat = this.destination.lat - this.start.lat;
          const dlng = this.destination.lng - this.start.lng;
          const cp1 = {
            lat: this.start.lat + 0.33 * dlat + 0.33 * dlng,
            lng: this.start.lng - 0.33 * dlat + 0.33 * dlng,
          };
          const cp2 = {
            lat: this.destination.lat - 0.33 * dlat + 0.33 * dlng,
            lng: this.destination.lng - 0.33 * dlat - 0.33 * dlng,
          };
          /* Bezier curve formula */
          return {
            lat:
              tick * tick * tick * this.start.lat +
              3 * ((1 - tick) * tick * tick) * cp1.lat +
              3 * ((1 - tick) * (1 - tick) * tick) * cp2.lat +
              (1 - tick) * (1 - tick) * (1 - tick) * this.destination.lat,
            lng:
              tick * tick * tick * this.start.lng +
              3 * ((1 - tick) * tick * tick) * cp1.lng +
              3 * ((1 - tick) * (1 - tick) * tick) * cp2.lng +
              (1 - tick) * (1 - tick) * (1 - tick) * this.destination.lng,
          };
        });
      }
      return {};
    },
  },
  destroyed() {
    clearTimeout(this.timeInterval);
  },
  async created() {
    this.setOrderTrackingDataEmpty();
    this.timelineKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.tracking-card-container {
  height: 1269px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  .search-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-left: 1rem;
    background: #ffffff;
    height: 54px;
    width: 390px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 9px;
    z-index: 5;
  }
  .header-slot {
    font-size: 12px;
    ::v-deep .v-input__slot::before {
      border-style: none !important;
    }
    ::v-deep .v-text-field {
      padding-top: unset !important;
      margin-top: 10px !important;
      height: 40px !important;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
    ::v-deep .v-input__slot:after {
      border-style: none !important;
    }
  }
  .search-input {
    margin-left: 8px;

    &::placeholder {
      font-size: 12px;
      color: grey;
    }
  }
}
</style>
