var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promotion-table-container pl-7 pr-7 width-100"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Promotion","cancel":"Cancel","content":"Are you sure you want to delete the selected Promotion ? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.promotionsCount === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.promotionHeaders,"items":_vm.promotions,"item-key":"id","hide-default-footer":"","server-items-length":_vm.promotionsCount,"options":{ page: _vm.page, itemsPerPage: _vm.limit }},on:{"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":"","items-per-page-options":_vm.itemsPerPage,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.productdetails",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.promotion_img)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.promotion_img)),"name":item.productdetails.name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.productdetails.name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.productdetails.name ? item.productdetails.name : "-")+" ")])],1)]}},{key:"item.product_description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"name ellipsis"},on),[_vm._v(" "+_vm._s(item.product_description ? item.product_description : "-")+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.product_description ? item.product_description : "-")+" ")])])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('a',{staticClass:"website cursor-pointer",attrs:{"href":_vm.formatWebsite(item.link),"target":"_blank"}},[_vm._v(" "+_vm._s(item.link)+" ")]):_c('div',{staticClass:"no-website"},[_vm._v("Not Available")])]}},{key:"item.createdby",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.createdby && item.createdby.profile_pic)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.createdby.profile_pic)),"name":item.createdby.name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.createdby.name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.createdby.name ? item.createdby.name : "-")+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"name display-flex flex-col"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" "),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatTime(item.created_at)))])])])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"name display-flex flex-col"},[_vm._v(" "+_vm._s(item.end_date ? _vm.formatDate(item.end_date) : "-")+" ")])])]}},{key:"item.customer_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('ActionButton',{staticClass:"mr-4",attrs:{"label":"Customers","count":item.promotioncount ? item.promotioncount : 0,"icon":"customer","width":"124px"},on:{"click":function($event){return _vm.customerClikced(item)}}})],1)]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"editClicked":function($event){return _vm.editClicked(props.item)},"deleteClicked":function($event){return _vm.deleteClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1)]}}])},[_vm._v(" --> ")])],1),_c('AddPromotion',{key:_vm.editKey,attrs:{"width":928,"show":_vm.showEdit,"title":"Edit Promotion","isEdit":true,"item":_vm.selectedItem},on:{"close":function($event){_vm.showEdit = false},"saveClicked":_vm.refresh}}),_c('CustomerListModal',{attrs:{"width":461,"show":_vm.showCustomer,"title":"Customers"},on:{"close":_vm.customerClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }