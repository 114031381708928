<template>
  <div>
    <div v-if="loggedUserPermission.management_dashboard" class="p-4">
      <v-row class="no-gutters">
        <v-col class="col-8">
          <StatusCard />
          <DeliveryReport class="mt-3" />
          <MerchantReport />
          <LogisticReport />
        </v-col>
        <v-col class="ml-4">
          <TrackingCard />
          <Report />
        </v-col>
      </v-row>
    </div>
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MerchantReport from "@/components/Admin/Dashboard/MerchantReport";
import StatusCard from "@/components/Admin/Dashboard/StatusCard";
import LogisticReport from "@/components/Admin/Dashboard/LogisticReport";
import DeliveryReport from "@/components/Admin/Dashboard/DeliveryReport";
import Report from "@/components/Admin/Dashboard/Report";
import TrackingCard from "@/components/Admin/Dashboard/TrackingCard";

export default {
  name: "AdminDashboard",
  components: {
    MerchantReport,
    StatusCard,
    LogisticReport,
    DeliveryReport,
    Report,
    TrackingCard,
  },
  computed: {
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
};
</script>

<style lang="scss" scoped></style>
