<template>
  <div>
    <CommonToolbar
      title="Promotions"
      :primaryButtonLabel="loggedUserPermission.view_promotions && loggedUserPermission.edit_promotions &&  'Add Promotion'"
      :secondaryButtonLabel="loggedUserPermission.view_promotions && 'Export'"
      :isSearch="loggedUserPermission.view_promotions"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_promotions"
    />
    <AddPromotion
      @saveClicked="saveClicked"
      :width="928"
      :show="show"
      :key="modalKey"
      @close="show = false"
      title="Add Promotion"
    />
    <TableSection v-if="loggedUserPermission.view_promotions" :key="tableKey" />
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddPromotion from "./AddPromotion.vue";
import TableSection from "./TableSection.vue";
export default {
  name: "AdminPromotion",
  components: {
    AddPromotion,
    TableSection,
  },
  data() {
    return {
      show: false,
      tableKey: 0,
      modalKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin/promotion", ["fromDate", "toDate"]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      setSearchQuery: "admin/promotion/setSearchQuery",
      setDateRange: "admin/promotion/setDateRange",
      exportPromotions: "admin/promotion/exportPromotions",
      setPage: "admin/promotion/setPage",
    }),
    addClick() {
      this.modalKey += 1;
      this.show = true;
    },
    async exportClick() {
      await this.exportPromotions();
    },
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    saveClicked() {
      this.show = false;
      this.tableKey += 1;
      this.modalKey += 1;
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
  },
  created() {
    this.setPage(1);
    this.setDateRange({});
    this.setSearchQuery("");
    this.tableKey += 1;
  },
};
</script>

<style lang="scss" scoped></style>
