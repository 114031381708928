<template>
  <div>
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pr-6 pl-6 display-flex width-100 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->
        <v-row class="no-gutters mt-4 mr-6">
          <v-col class="col-3">
            <ImageUpload
              placeholder="user-icon"
              label="User Image"
              :src="imgFile"
              @fileData="getProfileData"
              @removePic="removeProfilePic"
            />
          </v-col>
          <v-col class="mt-4">
            <v-row no-gutters>
              <v-col
                ><TextField
                  @change="getFormData($event, 'user_name')"
                  label="Name"
                  :required="true"
                  :value="formData.user_name"
                  :errorMessages="nameErrors"
                  :error="nameErrors && nameErrors.length > 0"
                  @input="$v.formData.user_name.$touch()"
                  @blur="$v.formData.user_name.$touch()"
              /></v-col>
              <v-col class="ml-3"
                ><SelectField
                  @change="getFormData($event, 'role_id')"
                  label="Role"
                  :required="true"
                  :value="formData.role_id"
                  :data-set="roleDataSet"
              /></v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Email"
                  :required="true"
                  :value="formData.email_address"
                  @change="getFormData($event, 'email_address')"
                  :errorMessages="emailErrors"
                  :error="emailErrors && emailErrors.length > 0"
                  @input="$v.formData.email_address.$touch()"
                  @blur="$v.formData.email_address.$touch()"
              /></v-col>
              <v-col class="ml-3"
                ><TextField
                  label="Mobile No."
                  :value="formData.phone_number"
                  @change="getFormData($event, 'phone_number')"
                  input-type="Number"
                  min="0"
              /></v-col>
            </v-row>

            <!--      <v-row no-gutters>
              <v-col
                ><TextField
                  label="Post Code"
                  :value="formData.postalcode"
                  @input="getFormData($event, 'postalcode')"
                  :debounce="true"
                  input-type="Number"
                  min="0"
              /></v-col>
              <v-col class="ml-3"
                ><SelectField
                  label="Country"
                  :value="formData.country"
                  @change="getFormData($event, 'country')"
                  :data-set="countryDataSet"
                  :key="countryKey"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="State"
                  :value="formData.state"
                  @change="getFormData($event, 'state')"
                  :key="stateKey"
              /></v-col>
              <v-col class="ml-3">
                <TextField
                  label="City"
                  :value="formData.cityortown"
                  @change="getFormData($event, 'cityortown')"
                  :key="cityKey"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  label="Address"
                  rows="5"
                  :value="formData.address"
                  @change="getFormData($event, 'address')"
                  :key="addressKey"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Building Name/Block Number"
                  :value="formData.block_number"
                  @change="getFormData($event, 'block_number')"
              /></v-col>
              <v-col class="ml-3"
                ><TextField
                  label="Floor - Unit Number"
                  :value="formData.floor_number"
                  @change="getFormData($event, 'floor_number')"
              /></v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <!-- footer -->
        <v-row class="justify-end footer-section pb-5">
          <div class="justify-end">
            <Button
              label="Close"
              type="cancel"
              width="94px"
              @click="onCancel"
            />
            <Button
              class="ml-3"
              label="Save"
              type="save"
              width="94px"
              @click="onSave"
              :disabled="isSubmitDisabled() || isLoading"
              :loading="isLoading"
            />
          </div>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";
import { Country, State } from "country-state-city";
import { SERVER_URL, DEFAULT_COUNTRY, NAME_REGEX } from "../../../../constants";
import { getAddressData } from "../../../services/utils.js";
const nameChecker = helpers.regex("nameValidate", NAME_REGEX);

export default {
  name: "AdminMerchantAddUser",
  mixins: [validationMixin],
  validations: {
    formData: {
      email_address: { required, email },
      user_name: { required, nameChecker },
      role_id: { required },
      phone_number: {},
      block_number: {},
      floor_number: {},
      postalcode: {},
      country: {},
      cityortown: {},
      address: {},
      dp: {},
    },
  },
  props: ["show", "width", "title", "item", "isEdit", "company_id"],
  data() {
    return {
      isLoading: false,
      serverUrl: SERVER_URL,
      formData: {
        user_name: "",
        role_id: "",
        block_number: "",
        floor_number: "",
        postalcode: "",
        country: "",
        state: "",
        cityortown: "",
        address: "",
        email_address: "",
        phone_number: "",
        dp: "",
        user_img: "",
      },
      imgFile: "",
      countryDataSet: [],
      stateDataSet: [],
      cityDataSet: [],
      roleDataSet: [],
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      stateDisabled: false,
      countryKey: 10,
      stateKey: 20,
      cityKey: 30,
      addressKey: 70,
    };
  },
  methods: {
    ...mapActions({
      addUser: "admin/merchantUsers/addUser",
      updateUser: "admin/merchantUsers/updateUser",
    }),
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      this.isLoading = true;
      try {
        const data = new FormData();
        if (this.formData.user_name)
          data.append("user_name", this.formData.user_name);
        if (this.formData.role_id)
          data.append("role_id", this.formData.role_id.id);
        if (this.formData.block_number)
          data.append("block_number", this.formData.block_number);
        if (this.formData.floor_number)
          data.append("floor_number", this.formData.floor_number);
        if (this.formData.postalcode)
          data.append("postalcode", this.formData.postalcode);
        if (this.formData.country)
          data.append("country", this.formData.country.name);
        if (this.formData.state) data.append("state", this.formData.state);
        if (this.formData.cityortown)
          data.append("cityortown", this.formData.cityortown);
        if (this.formData.address)
          data.append("address", this.formData.address);
        if (this.formData.email_address)
          data.append("email_address", this.formData.email_address);
        data.append("phone_number", this.formData.phone_number ? this.formData.phone_number : '');
        if (this.formData.dp) data.append("picture", this.formData.dp);
        data.append(
          "user_img",
          this.formData.user_img ? this.formData.user_img : null
        );
        if (this.company_id) data.append("company_id", this.company_id);
        this.isEdit
          ? await this.updateUser({ id: this.item.id, data: data })
          : await this.addUser(data);
        this.isLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      if (fieldName === "postalcode") {
        await getAddressData(value, (address) => {
          const country = this.countryDataSet.find(
            (obj) => obj.name === address.country
          );
          this.formData.state = address.administrative_area_level_1
            ? address.administrative_area_level_1
            : address.locality;
          this.formData.country = country;
          this.formData.cityortown = address.administrative_area_level_2;
          this.formData.address = address.fullAddress
            ? address.fullAddress
            : "";
          this.countryKey += 1;
          this.stateKey += 1;
          this.cityKey += 1;
          this.addressKey += 1;
        });
      }
    },
    getProfileData(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.formData.dp = files[0];
        this.imgFile = fr.result;
        this.formData.user_img = files[0].name;
      });
    },
    removeProfilePic() {
      this.imgFile = "";
      this.formData.dp = "";
      this.formData.user_img = "";
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    setEditFormData() {
      this.formData.user_name = this.item?.user_name;
      this.formData.block_number = this.item?.block_number;
      this.formData.floor_number = this.item?.floor_number;
      this.formData.postalcode = this.item.postalcode;
      const role = this.roleDataSet.find(
        (obj) => obj.id === this.item?.role_id
      );
      this.formData.role_id = role;
      this.formData.country = country;
      const country = this.countryDataSet.find(
        (obj) => obj.name === this.item.country
      );
      this.formData.country = country;
      this.formData.state = this.item?.state;
      this.formData.cityortown = this.item?.cityortown;
      this.formData.address = this.item?.address;
      this.formData.email_address = this.item?.email_address;
      this.formData.phone_number = this.item?.phone_number;
      this.formData.user_img = this.item?.user_img;
      this.imgFile = this.item.user_img
        ? `${this.serverUrl}/${this.item.user_img}`
        : "";
    },
    setDefaultCountry() {
      const country = this.countryDataSet.find(
        (obj) => obj.name === DEFAULT_COUNTRY
      );
      this.formData.country = country;
      this.formData.state = DEFAULT_COUNTRY;
      this.formData.cityortown = DEFAULT_COUNTRY;
      this.stateDataSet = State.getStatesOfCountry(country.isoCode);
    },
  },
  async created() {
    this.countryDataSet = Country.getAllCountries();
    this.setDefaultCountry();
    this.roleDataSet = this.merchantUserRoles;
    if (this.show && this.isEdit) {
      this.setEditFormData();
    }
  },
  computed: {
    ...mapGetters("admin/merchantUsers", ["merchantUserRoles"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email_address.$dirty) return errors;
      !this.$v.formData.email_address.required &&
        errors.push("Email is required.");
      !this.$v.formData.email_address.email &&
        errors.push("Must be valid e-mail");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formData.user_name.$dirty) return errors;
      !this.$v.formData.user_name.nameChecker &&
        errors.push("Name should be valid");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 300px;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
</style>
