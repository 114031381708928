<template>
  <div>
    <v-row class="no-gutters">
      <v-col class="status-card-layout exact-center">
        <div>
          <div class="header-title">Total Merchants</div>
          <SkeletonLoader
            v-if="isLoading"
            width="80"
            height="23"
            type="card"
          />
          <div v-else class="count">
            {{
              resourceCounts.merchantCount ? resourceCounts.merchantCount : 0
            }}
          </div>
        </div>
        <div class="ml-2">
          <img width="50" height="50" src="@/assets/icons/total-merchant.svg" />
        </div>
      </v-col>
      <v-col class="status-card-layout exact-center ml-3">
        <div>
          <div class="header-title">Total Logistics</div>
          <SkeletonLoader
            v-if="isLoading"
            width="80"
            height="23"
            type="card"
          />

          <div v-else class="count">
            {{
              resourceCounts.logisticsCount ? resourceCounts.logisticsCount : 0
            }}
          </div>
        </div>
        <div class="ml-2">
          <img width="50" height="50" src="@/assets/icons/frame.svg" />
        </div>
      </v-col>
      <v-col class="status-card-layout exact-center ml-3">
        <div>
          <div class="header-title">Total Customers</div>
          <SkeletonLoader
            v-if="isLoading"
            width="80"
            height="23"
            type="card"
          />

          <div v-else class="count">
            {{
              resourceCounts.customersCount ? resourceCounts.customersCount : 0
            }}
          </div>
        </div>
        <div class="ml-2">
          <img width="50" height="50" src="@/assets/icons/frame.svg" />
        </div>
      </v-col>
      <v-col class="status-card-layout exact-center ml-3">
        <div>
          <div class="header-title">Total Drivers</div>
          <SkeletonLoader
            v-if="isLoading"
            width="80"
            height="23"
            type="card"
          />

          <div v-else class="count">
            {{ resourceCounts.driversCount ? resourceCounts.driversCount : 0 }}
          </div>
        </div>
        <div class="ml-2">
          <img width="50" height="50" src="@/assets/icons/frame.svg" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StatusCard",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("admin/dashboard", ["resourceCounts"]),
  },
  methods: {
    ...mapActions({
      getResourceCounts: "admin/dashboard/getResourceCounts",
    }),
    async fetchAllCounts() {
      this.isLoading = true;
      await this.getResourceCounts();
      this.isLoading = false;
    },
  },
  async created() {
    await this.fetchAllCounts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.status-card-layout {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  height: 120px;
  color: #4a5571;

  .header-title {
    font-size: 14px;
    font-weight: 500;
  }
  .count {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
