<template>
  <div>
    <CommonToolbar
      title="Merchants"
      :primaryButtonLabel="
        loggedUserPermission.edit_merchants &&
          loggedUserPermission.view_merchants &&
          'Add Merchant'
      "
      :secondaryButtonLabel="loggedUserPermission.view_merchants && 'Export'"
      :isSearch="loggedUserPermission.view_merchants"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      :showDateFilter="false"
    >
      <!-- Filter Slot -->
      <v-edit-dialog ref="merchantFilterModal">
        <v-btn
          v-if="loggedUserPermission.view_merchants"
          class="mr-4 position-relative"
          icon
          dark
        >
          <img src="@/assets/icons/filter.svg" />
          <div
            class="filter-marker position-absolute"
            v-if="isMerchantFilter"
          ></div>
        </v-btn>
        <template v-slot:input>
          <MerchantFilter
            @onClose="sortResetFilterClicked"
            @onFilter="sortFilterClicked"
            :key="filterModalKey"
          />
        </template>
      </v-edit-dialog>
    </CommonToolbar>
    <TableComponent
      v-if="loggedUserPermission.view_merchants"
      :key="tableKey"
    />
    <NoAccess v-else class="no-content-container" />
    <AddMerchant
      :show="show"
      @close="show = false"
      title="Add Merchant"
      width="928"
      :key="addMerchantKey"
      @saveClicked="refresh"
    />
  </div>
</template>

<script>
import AddMerchant from "./AddMerchant.vue";
import MerchantFilter from "@/components/Admin/Merchant/MerchantFilter";
import TableComponent from "@/components/Admin/Merchant/TableComponent";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminMerchant",
  components: {
    AddMerchant,
    TableComponent,
    MerchantFilter,
  },
  data() {
    return {
      show: false,
      tableKey: 0,
      addMerchantKey: 100,
      filterModalKey: 30,
      sortDataset: ["Most Orders", "Least Orders", "Most Customers"],
    };
  },
  computed: {
    ...mapGetters("admin/merchants", [
      "fromDate",
      "toDate",
      "isMerchantFilter",
    ]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      setSearchQuery: "admin/merchants/setSearchQuery",
      setDateRange: "admin/merchants/setDateRange",
      exportMerchants: "admin/merchants/exportMerchants",
      setPage: "admin/merchants/setPage",
    }),
    ...mapActions({ setFilterValues: "admin/merchants/setFilterValues" }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    addClick() {
      this.addMerchantKey += 1;
      this.show = true;
    },
    async exportClick() {
      await this.exportMerchants();
    },
    refresh() {
      this.show = false;
      this.addMerchantKey += 1;
      this.tableKey += 1;
    },
    // resetClicked() {
    //   this.setDateRange({});
    //   this.tableKey += 1;
    // },
    // filterClicked(value) {
    //   this.setPage(1);
    //   this.setDateRange(value);
    //   this.tableKey += 1;
    // },
    sortFilterClicked() {
      this.setPage(1);
      this.$refs["merchantFilterModal"].isActive = false;
      this.tableKey += 1;
    },
    sortResetFilterClicked() {
      this.$refs["merchantFilterModal"].isActive = false;
      this.filterModalKey += 1;
      this.tableKey += 1;
    },
  },
  created() {
    this.setFilterValues(null);
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    this.tableKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -2px;
  right: -3px;
}
</style>
