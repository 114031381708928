<template>
  <div>
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pr-6 pl-6 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->
        <v-row class="no-gutters mt-4 mr-6">
          <v-col class="col-3">
            <ImageUpload
              placeholder="product"
              label="Promotion Image"
              :src="imgFile"
              @fileData="getProfileData"
              @removePic="removeProfilePic"
            />
          </v-col>
          <v-col>
            <v-row no-gutters>
              <v-col>
                <SelectField
                  @change="getFormData($event, 'merchant_id')"
                  label="Select Merchant"
                  :required="true"
                  itemText="company_name"
                  :value="formData.merchant_id"
                  :data-set="promotionMerchant"
                  @focus="getPromotionMerchants"
                  :disabled="isEdit"
                  :key="customerKey"
              /></v-col>
              <v-col class="ml-3">
                <SelectField
                  @change="getFormData($event, 'customer_id')"
                  label="Select Customer"
                  :disabled="!formData.merchant_id"
                  :required="true"
                  itemText="user_first_name"
                  :value="formData.customer_id"
                  :data-set="customerDataSet"
                  :multiple="true"
                  :key="customerKey"
                  :loading="customerLoading"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :key="productKey">
                <ActionSelection
                  @change="getFormData($event, 'product_id')"
                  label="Product name"
                  :required="true"
                  :disabled="!formData.merchant_id || isEdit"
                  :value="formData.product_id"
                  @focus="fetchProducts(companyID)"
                  :data-set="promotionProductDataSet"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <TextArea
                  label="Description"
                  rows="5"
                  :required="true"
                  :value="formData.product_description"
                  @change="getFormData($event, 'product_description')"
                  :key="descriptionKey"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                ><TextField
                  label="Link"
                  :value="formData.link"
                  @change="getFormData($event, 'link')"
                  :errorMessages="websiteErrors"
                  :error="websiteErrors && websiteErrors.length > 0"
                  @input="$v.formData.link.$touch()"
                  @blur="$v.formData.link.$touch()"
                  :key="linkKey"
              /></v-col>
              <v-col class="ml-3">
                <DatePicker
                  :minDate="minDate"
                  :maxDate="maxDate"
                  icon="calendar"
                  :dateValue="formData.end_date"
                  @change="getFormData($event, 'end_date')"
                  label="End date"
                  :key="end_date_key"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- footer -->
        <v-row class="justify-end footer-section pb-5">
          <div class="justify-end">
            <Button
              label="Close"
              type="cancel"
              width="94px"
              @click="onCancel"
            />
            <Button
              class="ml-3"
              label="Save"
              type="save"
              width="94px"
              @click="onSave"
              :disabled="isSubmitDisabled() || isLoading"
              :loading="isLoading"
            />
          </div>
        </v-row>
      </div>
    </v-dialog>
    <AddProduct
      :key="productModalKey"
      :width="920"
      :show="openAddProduct"
      @close="closeAddProduct"
      title="Add Product"
      @save="onProductAdd"
      :companyID="companyID"
    />
  </div>
</template>

<script>
import AddProduct from "./AddProduct";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL, URL_REGEX } from "../../../../constants";
const urlChecker = helpers.regex("urlValidate", URL_REGEX);

export default {
  name: "AdminAddPromotion",
  mixins: [validationMixin],
  validations: {
    formData: {
      product_id: { required },
      product_description: { required },
      customer_id: { required },
      link: { urlChecker },
      merchant_id: { required },
    },
  },
  components: { AddProduct },
  props: ["show", "width", "title", "isEdit", "item"],
  data() {
    return {
      serverUrl: SERVER_URL,
      isLoading: false,
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: "",
      markers: [{ position: { lat: 11.0168, lng: 76.9558 } }],
      formData: {
        product_id: "",
        product_description: "",
        link: "",
        customer_id: [],
        picture: "",
        promotion_img: "",
        end_date: "",
        merchant_id: "",
      },
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      imgFile: "",
      descriptionKey: 10,
      linkKey: 20,
      productKey: 30,
      customerKey: 40,
      promotionProductDataSet: [],
      productLoading: false,
      customerLoading: false,
      customerDataSet: [],
      isProductAdd: false,
      openAddProduct: false,
      productModalKey: 50,
      end_date_key: 80,
      companyID: "",
    };
  },
  methods: {
    ...mapActions({
      fetchPromotionProducts: "admin/promotion/fetchPromotionProducts",
      getCustomers: "admin/promotion/getPromotionCustomers",
      addPromotions: "admin/promotion/addPromotions",
      updatePromotions: "admin/promotion/updatePromotions",
      setProductPage: "admin/promotion/setProductPage",
      setProductSearchQuery: "admin/promotion/setProductSearchQuery",
      fetchCustmerDetails: "admin/promotion/fetchCustmerDetails",
      getPromotionMerchants: "admin/promotion/getPromotionMerchants",
      removeCustomerFromPromotion:
        "admin/promotion/removeCustomerFromPromotion",
      addCustomerToPromotion: "admin/promotion/addCustomerToPromotion",
    }),
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      try {
        this.isLoading = true;
        const data = new FormData();
        if (this.formData.product_id)
          data.append("product_id", this.formData.product_id?.id);
        if (this.formData.merchant_id)
          data.append("merchant_id", this.formData.merchant_id?.id);
        if (this.formData.product_description)
          data.append("product_description", this.formData.product_description);
        let customerArray = this.formData.customer_id.map((obj) => {
          return obj.id;
        });
        data.append("customer_id", JSON.stringify(customerArray));
        data.append("link", this.formData.link ? this.formData.link : "");
        data.append(
          "end_date",
          this.formData.end_date
            ? this.formData.end_date
            : ""
        );
        if (this.formData.picture)
          data.append("picture", this.formData.picture);
        data.append(
          "promotion_img",
          this.formData.promotion_img ? this.formData.promotion_img : null
        );
        this.isEdit
          ? await this.updatePromotions({ id: this.item.id, data })
          : await this.addPromotions(data);
        this.isLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
    async setEditFormData() {
      this.item.merchantcompany.company_name =
        this.item.merchantcompany && this.item.merchantcompany.name
          ? this.item.merchantcompany.name
          : "";
      this.formData.merchant_id = this.item?.merchantcompany;
      const product = this.promotionProductDataSet.find(
        (obj) => obj.id === this.item.productdetails.id
      );
      if (product) {
        this.formData.product_id = product;
      } else {
        this.formData.product_id = this.item.productdetails;
        this.promotionProductDataSet.push(this.item.productdetails);
      }
      this.formData.product_description = this.item?.product_description;
      await this.fetchCustmerDetails(this.item.id);
      this.customerDataSet = [...this.customerDataSet, ...this.customersData];
      this.formData.customer_id = this.customersData;
      // this.item.customer_id.map((obj) => {
      //   const customer = this.customerDataSet.find((item) => item.id === obj);
      //   this.formData.customer_id.push(customer);
      // });
      this.formData.link = this.item?.link;
      this.formData.promotion_img = this.item?.promotion_img;
      this.imgFile = this.item.promotion_img
        ? `${this.serverUrl}/${this.item.promotion_img}`
        : "";
      this.formData.end_date = this.item.end_date
        ? this.formatDate(this.item.end_date)
        : "";
      this.descriptionKey += 1;
      this.end_date_key += 1;
      this.linkKey += 1;
      this.productKey += 1;
      this.customerKey += 1;
    },
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    closeAddProduct() {
      this.openAddProduct = false;
      this.productModalKey += 1;
      this.formData["product_id"] = "";
      this.productKey += 1;
    },
    onProductAdd(product) {
      this.promotionProductDataSet.push(product);
      this.formData.product_id = product;
      this.productKey += 1;
      this.openAddProduct = false;
      this.productModalKey += 1;
    },
    async getFormData(value, fieldName) {
      if (fieldName === "customer_id" && this.isEdit) {
        let selected = null;
        if (this.formData.customer_id.length < value.length) {
          selected = value.filter(
            (x) => !this.formData.customer_id.includes(x)
          )[0];
        } else if (this.formData.customer_id.length > value.length) {
          selected = this.formData.customer_id.filter(
            (x) => !value.includes(x)
          )[0];
        }
        if (this.formData.customer_id.length > value.length) {
          this.customerLoading = true;
          await this.removeCustomerFromPromotion({
            promotion_id: this.item.id,
            customer_id: selected.id,
          });
          this.customerLoading = false;
        } else {
          this.customerLoading = true;
          await this.addCustomerToPromotion({
            promotion_id: this.item.id,
            customer_id: selected.id,
          });
          this.customerLoading = false;
        }
        this.formData.customer_id = value;
        return;
      }
      if (
        fieldName === "product_id" &&
        value.action &&
        value.action === "Add Product"
      ) {
        this.formData[fieldName] = "";
        this.openAddProduct = true;
        this.isProductAdd = true;
        this.productModalKey += 1;
        return;
      }
      if (fieldName === "merchant_id") {
        await this.getCustomers(value.id);
        await this.fetchProducts(value.id);
        this.customerDataSet = this.promotionCustomers;
        this.formData.customer_id = this.promotionCustomers;
        this.formData.merchant_id = value;
        this.companyID = value.id;
        this.customerKey += 1;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async fetchProducts(id) {
      await this.fetchPromotionProducts(id);
      this.promotionProductDataSet = [
        { action: "Add Product" },
        ...this.promotionProducts,
      ];
      this.productKey += 1;
    },
    getProfileData(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.formData.picture = files[0];
        this.imgFile = fr.result;
        this.formData.promotion_img = files[0].name;
      });
    },
    removeProfilePic() {
      this.imgFile = "";
      this.formData.picture = "";
      this.formData.promotion_img = "";
    },
  },
  async created() {
    await this.getPromotionMerchants();
    if (this.show && this.isEdit) {
      await this.getCustomers(this.item?.merchantcompany?.id);
      this.customerDataSet = this.promotionCustomers;
      await this.fetchProducts(this.item?.merchantcompany?.id);
      this.setEditFormData();
    }
  },
  computed: {
    ...mapGetters("admin/promotion", [
      "promotionProducts",
      "promotionCustomers",
      "promotionProductsCount",
      "productPage",
      "promotionCustomersCount",
      "customerPage",
      "customersData",
      "promotionMerchant",
    ]),
    websiteErrors() {
      const errors = [];
      if (!this.$v.formData.link.$dirty) return errors;
      !this.$v.formData.link.urlChecker && errors.push("Link must be valid");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
  //   overflow-y: scroll;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
