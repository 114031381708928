<template>
  <div class="merchant-inner-table-container width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete ${selectedType}`"
      cancel="Cancel"
      :content="
        `Are you sure you want to delete the selected ${selectedType}? `
      "
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <div
      class="inner-table-header display-flex justify-space-between align-center"
    >
      <div class="display-flex align-center">
        <img
          v-if="selectedType === 'Drivers'"
          src="@/assets/icons/driver.svg"
          class="mb-1"
        />
        <img
          v-else-if="selectedType === 'Deliveries'"
          src="@/assets/icons/delivery.svg"
          class="mb-1"
        />
        <img v-else src="@/assets/icons/user.svg" class="mb-1" />
        <div class="table-title ml-2">{{ selectedType }}</div>
        <SearchInput
          class="ml-4"
          @change="onSearch"
          :value="searchValue"
          :autofocus="true"
        />
        <DateFilter
          class="ml-4"
          @filterClicked="filterClicked"
          @resetClicked="resetClicked"
          :isFilter="isFilter"
          :dateFilterType="
            selectedType === 'Deliveries'
              ? deliveryDateFilterType
              : dateFilterType
          "
          :keyValue="keyValue"
        />
        <!-- Slot for Add on Filters -->
        <slot v-if="selectedType === 'Deliveries'"></slot>
        <Button
          v-if="selectedType === 'Deliveries'"
          @click="onExportRequest"
          label="Export"
          type="secondary"
          width="137px"
          class="ml-4"
        />
      </div>
      <div
        v-if="selectedType === 'Drivers' && loggedUserPermission.edit_logistics"
        class="button-container"
      >
        <Button
          label="+ Add Driver"
          type="cancel"
          width="94px"
          @click="onClickAddDriver"
        />
      </div>
      <div
        v-if="selectedType === 'Users' && loggedUserPermission.edit_logistics"
        class="button-container"
      >
        <Button
          label="+ Add Users"
          type="cancel"
          width="94px"
          @click="onAddUsers"
        />
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      :key="keyValue"
      hide-default-footer
      show-expand
      :server-items-length="totalItem"
      @update:page="updatePagination($event)"
      :options="{ page: page, itemsPerPage: 5 }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          show-first-last-page
        />
      </template>
      <template v-slot:item.driver_name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.driver_image"
            :url="`${serverUrl}/${item.driver_image}`"
            :name="item.driver_name"
            size="50"
            radius="12"
          />
          <Avatar v-else :name="item.driver_name" size="50" radius="12" />
          <div class="name ml-4">
            {{ item.driver_name ? item.driver_name : "-" }}
          </div>
        </div>
      </template>
      <template v-slot:item.license_number="{ item }">
        <div class="name">
          {{ item.license_number ? item.license_number : "-" }}
        </div>
      </template>

      <template v-slot:item.license_type="{ item }">
        <div class="name">
          {{ item.license_type ? item.license_type : "-" }}
        </div>
      </template>

      <template v-slot:item.email_address="{ item }">
        <div class="name">
          {{ item.email_address ? item.email_address : "-" }}
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="display-flex align-center">
          <div class="name">{{ item.name ? item.name : "-" }}</div>
        </div>
      </template>
      <template v-slot:item.tracking_id="{ item }">
        <div class="name">{{ item.tracking_id ? item.tracking_id : "-" }}</div>
      </template>
      <template v-slot:item.delivery_type="{ item }">
        <div class="name">
          {{ item.delivery_type ? item.delivery_type : "-" }}
        </div>
      </template>

      <template v-slot:item.contact_person="{ item }">
        <div class="name">
          {{ item.contact_person ? item.contact_person : "-" }}
        </div>
      </template>

      <template v-slot:item.contact_number="{ item }">
        <div class="name">
          {{ item.contact_number ? item.contact_number : "-" }}
        </div>
      </template>

      <template v-slot:item.pickupDate="{ item }">
        <div class="name">
          {{ item.pickupDate ? formatDate(item.pickupDate) : "-" }}
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="name" :style="{ color: orderStatus[item.status] }">
          {{ item.status ? item.status : "-" }}
        </div>
      </template>

      <template v-slot:item.deliveryType="{ item }">
        <div class="name">
          {{ item.deliveryType }}
        </div>
      </template>

      <template v-slot:item.estimated_delivery="{ item }">
        <div class="name">
          {{
            item.estimated_delivery ? formatDate(item.estimated_delivery) : "-"
          }}
        </div>
      </template>

      <template v-slot:item.driver="{ item }">
        <div class="name">
          {{ item.driver ? formatDate(item.driver) : "-" }}
        </div>
      </template>

      <!-- Users -->
      <template v-slot:item.user_name="{ item }">
        <div class="display-flex align-center">
          <Avatar
            v-if="item.user_img"
            :url="`${serverUrl}/${item.user_img}`"
            :name="item.user_name"
            size="50"
            radius="12"
          />
          <Avatar v-else :name="item.user_name" size="50" radius="12" />
          <div v-if="selectedType === 'Users'" class="name ml-2">
            {{ item.user_name ? item.user_name : "-" }}
          </div>
          <div v-else class="name">{{ item.name ? item.name : "-" }}</div>
        </div>
      </template>

      <template v-slot:item.role="{ item }">
        <div
          class="role exact-center normal-user"
          :class="{
            'super-user': item.role.name === 'Super User',
          }"
        >
          {{ item.role ? item.role.name : "-" }}
        </div>
      </template>

      <template v-slot:item.user_status="{ item }">
        <div
          class="role exact-center inactive-user"
          :class="{
            'active-user': !item.archived,
          }"
        >
          {{ item.archived ? "Inactive" : "Active" }}
        </div>
      </template>

      <template v-slot:item.phone_number="{ item }">
        <div class="name">
          {{ item.phone_number ? item.phone_number : "-" }}
        </div>
      </template>

      <template v-slot:item.last_login="{ item }">
        <div class="name">
          {{ item.last_login ? formatDate(item.last_login) : "-" }}
        </div>
      </template>

      <template v-slot:item.last_session="{ item }">
        <div class="name">
          {{ item.last_session ? formatDate(item.last_session) : "-" }}
        </div>
      </template>

      <template v-slot:item.created_at="{ item }">
        <div class="name">
          {{ item.created_at ? formatDate(item.created_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.address="{ item }">
        <div class="address-1">
          {{ item.block_number ? item.block_number + ", " : ""
          }}{{ item.floor_number ? item.floor_number + ", " : ""
          }}{{ item.address ? item.address : "-" }}
        </div>
        <div class="address-2" v-if="item.address">
          {{ item.cityortown ? item.cityortown + ", " : ""
          }}{{ item.state ? item.state + ", " : ""
          }}{{ item.country ? item.country : "" }}
        </div>
      </template>

      <template v-slot:item.pickup_address="{ item }">
        <!--   <div
          v-if="
            item.delivery_type !== 'Direct Delivery' &&
              item.sortingcenterdetails
          "
        >
          <div class="address-1">
            {{
              item.sortingcenterdetails
                ? item.sortingcenterdetails.address
                : "-"
            }}
          </div>
          <div class="address-2" v-if="item.sortingcenterdetails.address">
            {{
              item.sortingcenterdetails.cityortown
                ? item.sortingcenterdetails.cityortown + ", "
                : ""
            }}{{
              item.sortingcenterdetails.state
                ? item.sortingcenterdetails.state + ", "
                : ""
            }}{{
              item.sortingcenterdetails.country
                ? item.sortingcenterdetails.country
                : ""
            }}
          </div> 
        </div> -->
        <div>
          <div class="address-1">
            {{
              item.warehousedetails.block_number
                ? item.warehousedetails.block_number + ","
                : ""
            }}{{
              item.warehousedetails.floor_number
                ? item.warehousedetails.floor_number + ","
                : "-"
            }}{{ item.warehousedetails ? item.warehousedetails.address : "-" }}
          </div>
          <div class="address-2" v-if="item.warehousedetails.address">
            {{
              item.warehousedetails.cityortown
                ? item.warehousedetails.cityortown + ", "
                : ""
            }}{{
              item.warehousedetails.state
                ? item.warehousedetails.state + ", "
                : ""
            }}{{
              item.warehousedetails.country ? item.warehousedetails.country : ""
            }}
          </div>
        </div>
      </template>

      <template v-slot:item.delivery_address="{ item }">
        <div class="address-1">
          {{
            item.customerdetails.block_number
              ? item.customerdetails.block_number + ","
              : ""
          }}{{
            item.customerdetails.floor_number
              ? item.customerdetails.floor_number + ","
              : "-"
          }}{{ item.customerdetails ? item.customerdetails.address : "-" }}
        </div>
        <div class="address-2" v-if="item.customerdetails">
          {{
            item.customerdetails.cityortown
              ? item.customerdetails.cityortown + ", "
              : ""
          }}{{
            item.customerdetails.state ? item.customerdetails.state + ", " : ""
          }}{{
            item.customerdetails.country ? item.customerdetails.country : ""
          }}
        </div>
      </template>
      <template v-slot:item.product_quantity="{ item }">
        <div class="name">
          {{
            item.ordertrackingdetails.product_quantity
              ? item.ordertrackingdetails.product_quantity
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.merchant_name="{ item }">
        <div class="name">
          {{
            item.merchantcompany && item.merchantcompany.name ? item.merchantcompany.name : "-"
          }}
        </div>
      </template>

      <template v-slot:item.uom="{ item }">
        <div class="name">
          {{
            item.ordertrackingdetails.uom ? item.ordertrackingdetails.uom : "-"
          }}
        </div>
      </template>
      <template v-slot:item.expected_delivery_date="{ item }">
        <div class="name">
          {{
            item.ordertrackingdetails.expected_delivery_date
              ? formatProductDate(
                  item.ordertrackingdetails.expected_delivery_date
                )
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.pickup_date="{ item }">
        <div class="name">
          {{ item.pickup_date ? formatProductDate(item.pickup_date) : "-" }}
        </div>
      </template>

      <template v-slot:item.est_del_date="{ item }">
        <div class="name">
          {{
            item.estimation_delivery_date
              ? formatProductDate(item.estimation_delivery_date)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.assigned_driver="{ item }">
        <div class="name">
          {{ item.driverdetails ? item.driverdetails.driver_name : "-" }}
        </div>
      </template>

      <template v-slot:item.fax_no="{ item }">
        <div class="name">{{ item.fax_no ? item.fax_no : "-" }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="action-buttons display-flex align-center">
          <v-edit-dialog :return-value.sync="item.actions">
            <div class="more-options exact-center ml-2 cursor-pointer">
              <img src="@/assets/icons/more-options.svg" alt="more-options" />
            </div>
            <template v-slot:input>
              <InnerMoreOptions
                @deleteClicked="deleteClicked(item)"
                @editClicked="editClicked(item)"
                :isActiveInactive="selectedType === 'Users'"
                @activeToggle="activeClicked(item)"
                :active="!item.archived"
              />
            </template>
          </v-edit-dialog>
        </div>
      </template>
    </v-data-table>
    <AddDriver
      :show="showAddDriver"
      width="928"
      :item="selectedItem"
      :title="modalTitle"
      :key="driverModalKey"
      :company_id="item.id"
      :isEdit="isDriverEdit"
      @saveClicked="driverSaveClicked"
      @close="driverCloseClicked"
    />
    <AddUser
      width="928"
      :item="selectedItem"
      :show="showUser"
      :title="modalTitle"
      :key="userModalKey"
      :isEdit="isUserEdit"
      :company_id="item.id"
      @saveClicked="userSaveClicked"
      @close="userCloseClicked"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ORDER_STATUS, SERVER_URL } from "../../../../constants";
import AddDriver from "./AddDriver";
import AddUser from "./AddUser";

export default {
  name: "LogisiticInnerTable",
  components: { AddDriver, AddUser },
  props: [
    "headers",
    "selectedType",
    "items",
    "totalItem",
    "item",
    "page",
    "searchValue",
    "fromDate",
    "toDate",
    "isFilter",
    "keyValue",
  ],
  data() {
    return {
      orderStatus: ORDER_STATUS,
      deleteDialog: false,
      showAddDriver: false,
      showUser: false,
      modalTitle: "",
      driverModalKey: 0,
      selectedItem: {},
      userModalKey: 10,
      isUserEdit: false,
      isDriverEdit: false,
      serverUrl: SERVER_URL,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
      ],
      deliveryDateFilterType: [
        {
          name: "Request Received",
          value: "created_at",
        },
        {
          name: "Request Pending",
          value: "request_pending",
        },
        {
          name: "Order Delivered",
          value: "order_delivered",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      deleteLogisticUser: "admin/logisticUsers/deleteUser",
      deleteDriver: "admin/drivers/deleteDriver",
      getLogisticUserRoles: "admin/logisticUsers/getLogisticUserRoles",
      setLogisticActiveUser: "admin/logisticUsers/setLogisticActiveUser",
      exportRequest: "admin/requests/exportRequest",
    }),
    async onExportRequest() {
      await this.exportRequest(this.item?.id);
    },
    onClickAddDriver() {
      this.modalTitle = "Add Driver";
      this.showAddDriver = true;
      this.driverModalKey += 1;
    },
    driverSaveClicked() {
      this.$emit("refresh", {
        type: this.selectedType,
        logistic: this.item,
      });
    },
    userSaveClicked() {
      this.$emit("refresh", {
        type: this.selectedType,
        logistic: this.item,
      });
    },
    onSearch(value) {
      this.$emit("onSearch", {
        value,
        type: this.selectedType,
        logistic: this.item,
      });
    },
    filterClicked(value) {
      this.$emit("onFilter", {
        value,
        type: this.selectedType,
        logistic: this.item,
        isFilter: true,
      });
    },
    resetClicked() {
      this.$emit("onFilter", {
        value: {},
        type: this.selectedType,
        logistic: this.item,
        isFilter: false,
      });
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY | h:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    onClickReset(item) {
      this.items.map((obj) => {
        if (obj.id === item.id) obj.isResetSelected = !obj.isResetSelected;
        else obj.isResetSelected = false;
      });
    },
    onDeleteCancel() {
      this.deleteDialog = false;
      this.selectedItem = {};
    },
    updatePagination(value) {
      const type = this.selectedType;
      const page = value;
      this.$emit("updatePagination", { type, page, logistic: this.item });
    },
    async onDeleteConfirm() {
      if (this.selectedType === "Users") {
        try {
          await this.deleteLogisticUser(this.selectedItem);
          this.$emit("refresh", {
            type: this.selectedType,
            logistic: this.item,
          });
          this.deleteDialog = false;
        } catch (err) {
          console.log(err);
          this.deleteDialog = false;
        }
      }
      if (this.selectedType === "Drivers") {
        try {
          await this.deleteDriver(this.selectedItem);
          this.$emit("refresh", {
            type: this.selectedType,
            logistic: this.item,
          });
          this.deleteDialog = false;
        } catch (err) {
          console.log(err);
          this.deleteDialog = false;
        }
      }
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    async activeClicked(item) {
      try {
        item.archived
          ? await this.setLogisticActiveUser(item)
          : await this.deleteLogisticUser(item);
        this.$emit("refresh", {
          type: this.selectedType,
          logistic: this.item,
        });
      } catch (error) {
        console.log(error);
      }
    },
    driverCloseClicked() {
      this.isDriverEdit = false;
      this.selectedItem = {};
      this.showAddDriver = false;
    },
    userCloseClicked() {
      this.isUserEdit = false;
      this.selectedItem = {};
      this.showUser = false;
    },
    editClicked(item) {
      if (this.selectedType === "Drivers") {
        this.modalTitle = "Edit Driver";
        this.isDriverEdit = true;
        this.selectedItem = item;
        this.showAddDriver = true;
        this.driverModalKey += 1;
      } else if (this.selectedType === "Users") {
        this.modalTitle = "Edit User";
        this.isUserEdit = true;
        this.selectedItem = item;
        this.showUser = true;
        this.userModalKey += 1;
      }
    },
    onAddUsers() {
      this.modalTitle = "Add User";
      this.showUser = true;
      this.userModalKey += 1;
    },
    async getAllUserRoles() {
      try {
        await this.getLogisticUserRoles(this.item);
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  async created() {
    await this.getAllUserRoles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.merchant-inner-table-container {
  ::v-deep .v-data-footer__select {
    display: none;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: none !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }

  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 2.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .super-user {
    background: #ffede3 !important;
    color: #e87d1b !important;
  }
  .normal-user {
    background: #eeefff;
    color: #1b25c6;
  }
}
</style>
