var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-inner-table-container width-100"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":("Delete " + _vm.selectedType),"cancel":"Cancel","content":("Are you sure you want to delete the selected " + _vm.selectedType + "? "),"accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),_c('div',{staticClass:"inner-table-header display-flex justify-space-between align-center"},[_c('div',{staticClass:"display-flex align-center"},[(_vm.selectedType === 'Drivers')?_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/driver.svg")}}):(_vm.selectedType === 'Deliveries')?_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/delivery.svg")}}):_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/user.svg")}}),_c('div',{staticClass:"table-title ml-2"},[_vm._v(_vm._s(_vm.selectedType))]),_c('SearchInput',{staticClass:"ml-4",attrs:{"value":_vm.searchValue,"autofocus":true},on:{"change":_vm.onSearch}}),_c('DateFilter',{staticClass:"ml-4",attrs:{"isFilter":_vm.isFilter,"dateFilterType":_vm.selectedType === 'Deliveries'
            ? _vm.deliveryDateFilterType
            : _vm.dateFilterType,"keyValue":_vm.keyValue},on:{"filterClicked":_vm.filterClicked,"resetClicked":_vm.resetClicked}}),(_vm.selectedType === 'Deliveries')?_vm._t("default"):_vm._e(),(_vm.selectedType === 'Deliveries')?_c('Button',{staticClass:"ml-4",attrs:{"label":"Export","type":"secondary","width":"137px"},on:{"click":_vm.onExportRequest}}):_vm._e()],2),(_vm.selectedType === 'Drivers' && _vm.loggedUserPermission.edit_logistics)?_c('div',{staticClass:"button-container"},[_c('Button',{attrs:{"label":"+ Add Driver","type":"cancel","width":"94px"},on:{"click":_vm.onClickAddDriver}})],1):_vm._e(),(_vm.selectedType === 'Users' && _vm.loggedUserPermission.edit_logistics)?_c('div',{staticClass:"button-container"},[_c('Button',{attrs:{"label":"+ Add Users","type":"cancel","width":"94px"},on:{"click":_vm.onAddUsers}})],1):_vm._e()]),_c('v-data-table',{key:_vm.keyValue,attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","hide-default-footer":"","show-expand":"","server-items-length":_vm.totalItem,"options":{ page: _vm.page, itemsPerPage: 5 }},on:{"update:page":function($event){return _vm.updatePagination($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
            var pagination = ref.pagination;
            var options = ref.options;
            var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","show-first-last-page":""},on:{"update:options":updateOptions}})]}},{key:"item.driver_name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.driver_image)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.driver_image)),"name":item.driver_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.driver_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.driver_name ? item.driver_name : "-")+" ")])],1)]}},{key:"item.license_number",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.license_number ? item.license_number : "-")+" ")])]}},{key:"item.license_type",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.license_type ? item.license_type : "-")+" ")])]}},{key:"item.email_address",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.email_address ? item.email_address : "-")+" ")])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name ? item.name : "-"))])])]}},{key:"item.tracking_id",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.tracking_id ? item.tracking_id : "-"))])]}},{key:"item.delivery_type",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.delivery_type ? item.delivery_type : "-")+" ")])]}},{key:"item.contact_person",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.contact_person ? item.contact_person : "-")+" ")])]}},{key:"item.contact_number",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.contact_number ? item.contact_number : "-")+" ")])]}},{key:"item.pickupDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.pickupDate ? _vm.formatDate(item.pickupDate) : "-")+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name",style:({ color: _vm.orderStatus[item.status] })},[_vm._v(" "+_vm._s(item.status ? item.status : "-")+" ")])]}},{key:"item.deliveryType",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.deliveryType)+" ")])]}},{key:"item.estimated_delivery",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.estimated_delivery ? _vm.formatDate(item.estimated_delivery) : "-")+" ")])]}},{key:"item.driver",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.driver ? _vm.formatDate(item.driver) : "-")+" ")])]}},{key:"item.user_name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.user_img)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.user_img)),"name":item.user_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.user_name,"size":"50","radius":"12"}}),(_vm.selectedType === 'Users')?_c('div',{staticClass:"name ml-2"},[_vm._v(" "+_vm._s(item.user_name ? item.user_name : "-")+" ")]):_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name ? item.name : "-"))])],1)]}},{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"role exact-center normal-user",class:{
          'super-user': item.role.name === 'Super User',
        }},[_vm._v(" "+_vm._s(item.role ? item.role.name : "-")+" ")])]}},{key:"item.user_status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"role exact-center inactive-user",class:{
          'active-user': !item.archived,
        }},[_vm._v(" "+_vm._s(item.archived ? "Inactive" : "Active")+" ")])]}},{key:"item.phone_number",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : "-")+" ")])]}},{key:"item.last_login",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.last_login ? _vm.formatDate(item.last_login) : "-")+" ")])]}},{key:"item.last_session",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.last_session ? _vm.formatDate(item.last_session) : "-")+" ")])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.created_at ? _vm.formatDate(item.created_at) : "-")+" ")])]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.block_number ? item.block_number + ", " : "")+_vm._s(item.floor_number ? item.floor_number + ", " : "")+_vm._s(item.address ? item.address : "-")+" ")]),(item.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.cityortown ? item.cityortown + ", " : "")+_vm._s(item.state ? item.state + ", " : "")+_vm._s(item.country ? item.country : "")+" ")]):_vm._e()]}},{key:"item.pickup_address",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.warehousedetails.block_number ? item.warehousedetails.block_number + "," : "")+_vm._s(item.warehousedetails.floor_number ? item.warehousedetails.floor_number + "," : "-")+_vm._s(item.warehousedetails ? item.warehousedetails.address : "-")+" ")]),(item.warehousedetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.warehousedetails.cityortown ? item.warehousedetails.cityortown + ", " : "")+_vm._s(item.warehousedetails.state ? item.warehousedetails.state + ", " : "")+_vm._s(item.warehousedetails.country ? item.warehousedetails.country : "")+" ")]):_vm._e()])]}},{key:"item.delivery_address",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.customerdetails.block_number ? item.customerdetails.block_number + "," : "")+_vm._s(item.customerdetails.floor_number ? item.customerdetails.floor_number + "," : "-")+_vm._s(item.customerdetails ? item.customerdetails.address : "-")+" ")]),(item.customerdetails)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.customerdetails.cityortown ? item.customerdetails.cityortown + ", " : "")+_vm._s(item.customerdetails.state ? item.customerdetails.state + ", " : "")+_vm._s(item.customerdetails.country ? item.customerdetails.country : "")+" ")]):_vm._e()]}},{key:"item.product_quantity",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.product_quantity ? item.ordertrackingdetails.product_quantity : "-")+" ")])]}},{key:"item.merchant_name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.merchantcompany && item.merchantcompany.name ? item.merchantcompany.name : "-")+" ")])]}},{key:"item.uom",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.uom ? item.ordertrackingdetails.uom : "-")+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.expected_delivery_date ? _vm.formatProductDate( item.ordertrackingdetails.expected_delivery_date ) : "-")+" ")])]}},{key:"item.pickup_date",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.pickup_date ? _vm.formatProductDate(item.pickup_date) : "-")+" ")])]}},{key:"item.est_del_date",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.estimation_delivery_date ? _vm.formatProductDate(item.estimation_delivery_date) : "-")+" ")])]}},{key:"item.assigned_driver",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.driverdetails ? item.driverdetails.driver_name : "-")+" ")])]}},{key:"item.fax_no",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.fax_no ? item.fax_no : "-"))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":item.actions},on:{"update:returnValue":function($event){return _vm.$set(item, "actions", $event)},"update:return-value":function($event){return _vm.$set(item, "actions", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{attrs:{"isActiveInactive":_vm.selectedType === 'Users',"active":!item.archived},on:{"deleteClicked":function($event){return _vm.deleteClicked(item)},"editClicked":function($event){return _vm.editClicked(item)},"activeToggle":function($event){return _vm.activeClicked(item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1)]}}])}),_c('AddDriver',{key:_vm.driverModalKey,attrs:{"show":_vm.showAddDriver,"width":"928","item":_vm.selectedItem,"title":_vm.modalTitle,"company_id":_vm.item.id,"isEdit":_vm.isDriverEdit},on:{"saveClicked":_vm.driverSaveClicked,"close":_vm.driverCloseClicked}}),_c('AddUser',{key:_vm.userModalKey,attrs:{"width":"928","item":_vm.selectedItem,"show":_vm.showUser,"title":_vm.modalTitle,"isEdit":_vm.isUserEdit,"company_id":_vm.item.id},on:{"saveClicked":_vm.userSaveClicked,"close":_vm.userCloseClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }