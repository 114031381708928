<template>
  <div v-if="!isLoading">
    <AdminHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AdminHeader from "@/components/Admin/Header/Header.vue";

export default {
  name: "AdminMain",
  components: { AdminHeader },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getLoggedUserData: "admin/authentication/getLoggedUserData",
    }),
  },
  async created() {
    this.isLoading = true;
    await this.getLoggedUserData();
    this.isLoading = false;
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>
