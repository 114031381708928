<template>
  <div class="merchant-table-container pl-7 pr-7 width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Logistic`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Logistic ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="adminLogisticCount === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="logisticsHeaders"
        :items="adminLogistics"
        :expanded.sync="expanded"
        item-key="id"
        hide-default-footer
        show-expand
        :options="{ page: page, itemsPerPage: limit }"
        :server-items-length="adminLogisticCount"
        @update:page="updatePagination($event)"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:item.company_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.company_img"
              :name="item.company_name"
              :url="`${serverUrl}/${item.company_img}`"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.company_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.company_name ? item.company_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.company_status="{ item }">
          <div
            class="role exact-center inactive-user"
            :class="{
              'active-user': !item.archived,
            }"
          >
            {{ item.archived ? "Inactive" : "Active" }}
          </div>
        </template>

        <template v-slot:item.contact_person="{ item }">
          <div class="name">
            {{ item.contact_person ? item.contact_person : "-" }}
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="name">
            {{ item.contact_number ? item.contact_number : "-" }}
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address ? item.address : "-" }}
          </div>
          <div class="address-2" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
        </template>

        <template v-slot:item.phone_number="{ item }">
          <div class="name">
            {{ item.phone_number ? item.phone_number : "-" }}
          </div>
        </template>

        <template v-slot:item.fax_number="{ item }">
          <div class="name">{{ item.fax_number ? item.fax_number : "-" }}</div>
        </template>

        <template v-slot:item.email_address="{ item }">
          <div class="name">
            {{ item.email_address ? item.email_address : "-" }}
          </div>
        </template>

        <template v-slot:item.website="{ item }">
          <a
            :href="formatWebsite(item.website)"
            class="website cursor-pointer"
            target="_blank"
            v-if="item.website"
          >
            {{ item.website }}
          </a>
          <div class="no-website" v-else>Not Available</div>
        </template>

        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <ActionButton
              label="Drivers"
              :count="props.item.driverscount ? props.item.driverscount : 0"
              icon="driver"
              width="124px"
              class="mr-4"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Drivers', props.item.type)"
              :active="props.item.driverSelected"
            />
            <ActionButton
              label="Users"
              :count="props.item.usercount ? props.item.usercount : 0"
              icon="user"
              width="108px"
              class="mr-4"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Users', props.item.type)"
              :active="props.item.usersSelected"
            />
            <ActionButton
              label="Deliveries"
              :count="props.item.requestcount ? props.item.requestcount : 0"
              icon="delivery"
              width="130px"
              class="mr-4"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Deliveries', props.item.type)"
              :active="props.item.deliverySelected"
            />
            <v-edit-dialog
              v-if="loggedUserPermission.edit_logistics"
              :return-value.sync="props.item.action"
            >
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                  :isActiveInactive="true"
                  @activeToggle="activeClicked(props.item)"
                  :active="!props.item.archived"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <Spinner
              class="inner-spinner-class exact-center"
              v-if="isTableLoading"
            />
            <InnerTable
              v-else
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :totalItem="item.totalItem"
              :page="item.page"
              :keyValue="innerTableKey"
              :searchValue="item.searchValue"
              :isFilter="item.isFilter"
              @updatePagination="updateInnerTablePage"
              @refresh="refreshInnerTable"
              @onSearch="onSearch"
              @onFilter="onFilter"
            >
              <div class="ml-4 pt-4" style="height: 120px">
                <SelectField
                  label="Merchant"
                  :data-set="['NA', ...merchantDropdown]"
                  itemText="company_name"
                  @change="getFormData($event, 'merchantFilter', item)"
                />
              </div>
            </InnerTable>
          </td>
        </template>
      </v-data-table>
      <AddLogistics
        :width="928"
        :isEdit="true"
        :item="selectedItem"
        :show="showEditLogistic"
        @close="closeEditModal"
        title="Edit Logistic"
        @saveClicked="refresh"
        :key="addLogisticKey"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InnerTable from "@/components/Admin/Logistics/InnerTable";
import AddLogistics from "@/components/Admin/Logistics/AddLogistics";
import { SERVER_URL, ITEMS_PER_PAGE, HTTP_REGEX } from "../../../../constants";

export default {
  name: "LogisticsTableComponent",
  components: { InnerTable, AddLogistics },
  data() {
    return {
      isLoading: false,
      expanded: [],
      serverUrl: SERVER_URL,
      itemsPerPage: ITEMS_PER_PAGE,
      showEditLogistic: false,
      deleteDialog: false,
      isTableLoading: false,
      addLogisticKey: 10,
      innerTableKey: 20,
      selectedItem: {},
      logisticsHeaders: [
        {
          text: "Logistics Name",
          align: "start",
          sortable: false,
          value: "company_name",
          width: "250px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "company_status",
          width: "250px",
        },
        {
          text: "Contact Person",
          align: "start",
          sortable: false,
          value: "contact_person",
          width: "150px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
          width: "150px",
        },
        {
          text: "Address",
          align: "start",
          sortable: false,
          value: "address",
          width: "300px",
        },
        {
          text: "Phone No.",
          align: "start",
          sortable: false,
          value: "phone_number",
        },
        {
          text: "Fax No.",
          align: "start",
          sortable: false,
          value: "fax_number",
          width: "150px",
        },
        {
          text: "Email ID",
          align: "start",
          sortable: false,
          value: "email_address",
          width: "150px",
        },
        {
          text: "Website",
          align: "start",
          sortable: false,
          value: "website",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      usersHeaders: [
        {
          text: "User Name",
          align: "start",
          sortable: false,
          value: "user_name",
          width: "250px",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
          width: "250px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "user_status",
          width: "250px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "phone_number",
          width: "250px",
        },
        // {
        //   text: "Reset Password",
        //   align: "center",
        //   sortable: false,
        //   value: "resetPassword",
        //   width: "250px",
        // },
        {
          text: "Last Login Date",
          align: "start",
          sortable: false,
          value: "last_login",
          width: "250px",
        },
        // {
        //   text: "Last Session",
        //   align: "start",
        //   sortable: false,
        //   value: "last_session",
        //   width: "250px",
        // },
        {
          text: "Created Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "250px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          width: "250px",
        },
      ],
      driverHeaders: [
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "Total Orders",
          align: "start",
          sortable: false,
          value: "ordercount",
        },
        {
          text: "Order Completed",
          align: "start",
          sortable: false,
          value: "ordercompletedcount",
        },
        {
          text: "Order Pending",
          align: "start",
          sortable: false,
          value: "orderpendingcount",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
        },
        {
          text: "License Type",
          align: "start",
          sortable: false,
          value: "license_type",
        },
        {
          text: "License No.",
          align: "start",
          sortable: false,
          value: "license_number",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email_address",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      deliveryHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "150px",
        },
        {
          text: "Delivery Type",
          align: "start",
          sortable: false,
          value: "delivery_type",
          width: "180px",
        },
        {
          text: "Merchant",
          align: "start",
          sortable: false,
          value: "merchant_name",
          width: "210px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "200px",
        },
        {
          text: "Pickup Address",
          align: "start",
          sortable: false,
          value: "pickup_address",
          width: "200px",
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "delivery_address",
          width: "200px",
        },
        {
          text: "Qty",
          align: "start",
          sortable: false,
          value: "product_quantity",
          width: "30px",
        },
        {
          text: "UOM",
          align: "start",
          sortable: false,
          value: "uom",
          width: "150px",
        },
        {
          text: "Exp del date",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
          width: "150px",
        },
        {
          text: "Assigned Driver",
          align: "start",
          sortable: false,
          value: "assigned_driver",
          width: "150px",
        },
        {
          text: "Pick up Date",
          align: "start",
          sortable: false,
          value: "pickup_date",
          width: "150px",
        },
        {
          text: "Est Del Date",
          align: "start",
          sortable: false,
          value: "est_del_date",
          width: "150px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
    ...mapGetters("admin/logistics", [
      "adminLogisticCount",
      "adminLogistics",
      "page",
      "limit",
    ]),
    ...mapGetters("admin/logisticUsers", [
      "logisticUsers",
      "totalLogisticUsers",
      "logisticUserPage",
      "logisticFromDate",
      "logistciToDate",
    ]),
    ...mapGetters("admin/drivers", [
      "drivers",
      "totalDrivers",
      "logisticDriverPage",
      "driverFromDate",
      "driverToDate",
    ]),
    ...mapGetters("admin/requests", [
      "requests",
      "totalRequest",
      "requestPage",
      "requestFromDate",
      "requestToDate",
      "merchantDropdown"
    ]),
    computedDriverHeaders() {
      this.driverHeaders.map((item, i) => {
        if (
          item.text === "Actions" &&
          !this.loggedUserPermission.edit_logistics
        ) {
          this.driverHeaders.splice(i, 1);
        }
      });
      return this.driverHeaders;
    },
    computedUserHeaders() {
      this.usersHeaders.map((item, i) => {
        if (
          item.text === "Actions" &&
          !this.loggedUserPermission.edit_logistics
        ) {
          this.usersHeaders.splice(i, 1);
        }
      });
      return this.usersHeaders;
    },
  },
  methods: {
    ...mapActions({
      getLogistics: "admin/logistics/getLogistics",
      deleteLogistic: "admin/logistics/deleteLogistic",
      setActiveLogistic: "admin/logistics/setActiveLogistic",
      setPage: "admin/logistics/setPage",
      setLimit: "admin/logistics/setLimit",
      getLogisticUsers: "admin/logisticUsers/getUsers",
      setLogisticUserPage: "admin/logisticUsers/setPage",
      getDrivers: "admin/drivers/getDrivers",
      setDriversPage: "admin/drivers/setPage",
      setUserSearchQuery: "admin/logisticUsers/setSearchQuery",
      setDriversSearchQuery: "admin/drivers/setSearchQuery",
      setLogisticsUserDateRange: "admin/logisticUsers/setDateRange",
      setDriversDateRange: "admin/drivers/setDateRange",
      getRequests: "admin/requests/getRequests",
      setRequestPage: "admin/requests/setPage",
      setRequestSearchQuery: "admin/requests/setSearchQuery",
      setRequestDateRange: "admin/requests/setDateRange",
      getMerchantDropdown: "admin/requests/getMerchantDropdown",
      setMerchantFilter: "admin/requests/setMerchantFilter",
      setMerchantIDEmpty: "admin/requests/setMerchantIDEmpty",
    }),
    async getFormData(value, fieldName, item) {
      if (fieldName === "merchantFilter") {
        if (value === "NA") {
          await this.setMerchantFilter({ id: item.id, merchant_id: "" });
          item.innerItems = this.requests;
          item.page = this.requestPage;
          item.totalItem = this.totalRequest;
          item.requestcount = this.totalRequest;
          this.innerTableKey += 1;
          return;
        }
        await this.setMerchantFilter({ id: item.id, merchant_id: value.id });
        item.innerItems = this.requests;
        item.page = this.requestPage;
        item.totalItem = this.totalRequest;
        item.requestcount = this.totalRequest;
        this.innerTableKey += 1;
      }
    },
    formatWebsite(url) {
      if (!HTTP_REGEX.test(url)) {
        url = "http://" + url;
      }
      return url;
    },
    async setUserData(item) {
      this.isTableLoading = true;
      try {
        await this.getLogisticUsers(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async setDriverData(item) {
      this.isTableLoading = true;
      try {
        await this.getDrivers(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async setRequestData(item) {
      this.isTableLoading = true;
      try {
        await this.getRequests(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async updatePagination(value) {
      this.expanded = [];
      this.setPage(value);
      await this.fetchData();
    },
    async updatePerPage(value) {
      this.expanded = [];
      this.setLimit(value);
      await this.fetchData();
    },
    async refreshInnerTable(selectedItem) {
      this.adminLogistics.map(async (item) => {
        if (item.id === selectedItem?.logistic?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.innerTableKey += 1;
              await this.setUserData(item);
              item.innerItems = this.logisticUsers;
              item.page = this.logisticUserPage;
              item.totalItem = this.totalLogisticUsers;
              item.usercount = this.totalLogisticUsers;
              this.isTableLoading = false;
              break;
            case "Drivers":
              this.innerTableKey += 1;
              await this.setDriverData(item);
              item.innerItems = this.drivers;
              item.page = this.logisticDriverPage;
              item.totalItem = this.totalDrivers;
              item.driverscount = this.totalDrivers;
              this.isTableLoading = false;
              break;
            case "Deliveries":
              this.innerTableKey += 1;
              await this.setRequestData(item);
              item.innerItems = this.requests;
              item.page = this.requestPage;
              item.totalItem = this.totalRequest;
              item.requestcount = this.totalRequest;
              this.isTableLoading = false;
              break;
          }
        }
      });
    },
    async onSearch(selectedItem) {
      this.adminLogistics.map(async (item) => {
        if (item.id === selectedItem?.logistic?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setUserSearchQuery(selectedItem.value);
              await this.fetchUsersData(item);
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
            case "Drivers":
              this.setDriversSearchQuery(selectedItem.value);
              await this.fetchDriversData(item);
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
            case "Deliveries":
              this.setRequestSearchQuery(selectedItem.value);
              await this.fetchRequestData(item);
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async onFilter(selectedItem) {
      this.adminLogistics.map(async (item) => {
        if (item.id === selectedItem?.logistic?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setLogisticsUserDateRange(selectedItem.value);
              await this.fetchUsersData(item);
              item.fromDate = selectedItem.value.fromDate;
              item.toDate = selectedItem.value.toDate;
              item.isFilter = selectedItem.isFilter;
              this.innerTableKey += 1;
              break;
            case "Drivers":
              this.setDriversDateRange(selectedItem.value);
              await this.fetchDriversData(item);
              item.isFilter = selectedItem.isFilter;
              item.fromDate = selectedItem.value.fromDate;
              item.toDate = selectedItem.value.toDate;
              this.innerTableKey += 1;
              break;
            case "Deliveries":
              this.setRequestDateRange(selectedItem.value);
              await this.fetchRequestData(item);
              item.isFilter = selectedItem.isFilter;
              item.fromDate = selectedItem.value.fromDate;
              item.toDate = selectedItem.value.toDate;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async fetchUsersData(item) {
      this.setLogisticUserPage(1);
      await this.getLogisticUsers(item);
      item.innerItems = this.logisticUsers;
      item.page = this.logisticUserPage;
      item.totalItem = this.totalLogisticUsers;
    },
    async fetchDriversData(item) {
      this.setDriversPage(1);
      await this.getDrivers(item);
      item.innerItems = this.drivers;
      item.page = this.logisticDriverPage;
      item.totalItem = this.totalDrivers;
    },
    async fetchRequestData(item) {
      this.setRequestPage(1);
      await this.getRequests(item);
      item.innerItems = this.requests;
      item.page = this.requestPage;
      item.totalItem = this.totalRequest;
    },
    async updateInnerTablePage(selectedItem) {
      this.adminLogistics.map(async (item) => {
        if (item.id === selectedItem?.logistic?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setLogisticUserPage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setUserData(item);
              item.innerItems = this.logisticUsers;
              item.page = this.logisticUserPage;
              this.isTableLoading = false;
              break;
            case "Drivers":
              this.setDriversPage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setDriverData(item);
              item.innerItems = this.drivers;
              item.page = this.logisticDriverPage;
              item.totalItem = this.totalDrivers;
              this.isTableLoading = false;
              break;
            case "Deliveries":
              this.setRequestPage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setRequestData(item);
              item.innerItems = this.requests;
              item.page = this.requestPage;
              item.totalItem = this.totalRequest;
              this.isTableLoading = false;
              break;
          }
        }
      });
    },
    expandClicked(slotData, type, selectedType) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1 && selectedType === type) {
        this.expanded.splice(indexExpanded, 1);
        this.adminLogistics.map((item) => {
          if (item.id === slotData.item.id) {
            item.driverSelected = false;
            item.deliverySelected = false;
            item.usersSelected = false;
            item.selected = false;
            item.isFilter = false;
            this.setLogisticsUserDateRange({});
            this.setDriversDateRange({});
            this.setRequestDateRange({});
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.adminLogistics.map(async (item) => {
          if (item.id === slotData.item.id) {
            switch (type) {
              case "Drivers":
                item.driverSelected = true;
                item.deliverySelected = false;
                item.usersSelected = false;
                item.selected = true;
                this.setDriversDateRange({});
                item.isFilter = this.driverFromDate && this.driverToDate;
                this.setDriversSearchQuery("");
                this.setDriversPage(1);
                await this.setDriverData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.innerHeaders = this.computedDriverHeaders;
                item.innerItems = this.drivers;
                item.page = this.logisticDriverPage;
                item.totalItem = this.totalDrivers;
                item.type = type;
                item.searchValue = "";
                break;
              case "Users":
                item.driverSelected = false;
                item.usersSelected = true;
                item.deliverySelected = false;
                item.selected = true;
                this.setLogisticsUserDateRange({});
                item.isFilter = this.logisticFromDate && this.logisticToDate;
                await this.setUserSearchQuery("");
                this.setLogisticUserPage(1);
                await this.setUserData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.innerHeaders = this.computedUserHeaders;
                item.innerItems = this.logisticUsers;
                item.page = this.logisticUserPage;
                item.totalItem = this.totalLogisticUsers;
                item.type = type;
                item.searchValue = "";
                break;
              case "Deliveries":
                await this.getMerchantDropdown(item.id);
                item.driverSelected = false;
                item.usersSelected = false;
                item.deliverySelected = true;
                item.selected = true;
                this.setRequestDateRange({});
                item.isFilter = this.requestFromDate && this.requestToDate;
                await this.setRequestSearchQuery("");
                this.setRequestPage(1);
                await this.setRequestData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.innerHeaders = this.deliveryHeaders;
                item.innerItems = this.requests;
                item.page = this.requestPage;
                item.totalItem = this.totalRequest;
                item.type = type;
                item.searchValue = "";
                break;
            }
          } else {
            item.driverSelected = false;
            item.deliverySelected = false;
            item.selected = false;
            item.usersSelected = false;
          }
        });
      }
    },
    async fetchData() {
      this.isLoading = true;
      await this.getLogistics();
      this.isLoading = false;
    },
    editClicked(item) {
      this.selectedItem = item;
      this.showEditLogistic = true;
      this.addLogisticKey += 1;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
      this.selectedItem = {};
    },
    async onDeleteConfirm() {
      this.deleteDialog = false;
      await this.deleteLogistic(this.selectedItem);
      await this.fetchData();
      this.onDeleteCancel();
    },
    async activeClicked(item) {
      item.archived
        ? await this.setActiveLogistic(item)
        : await this.deleteLogistic(item);
      await this.fetchData();
    },
    closeEditModal() {
      this.showEditLogistic = false;
      this.selectedItem = {};
    },
    async refresh() {
      this.showEditLogistic = false;
      await this.fetchData();
    },
  },
  created() {
    this.expanded = [];

    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .v-icon {
  display: none !important;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}

.merchant-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .button-disable {
    pointer-events: none;
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .website {
    color: #2b5cdb;
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: underline;
  }
  .no-website {
    color: #4a557180;
    font-weight: 600;
    font-size: 0.875rem;
  }
}
</style>
