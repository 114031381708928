<template>
  <div class="dashboard-report">
    <Spinner v-if="isLoading" size="30" class="spinner-class exact-center" />
    <div v-else class="justify-space-between pt-6">
      <div class="display-flex">
        <div class="ml-6 mr-4">
          <div class="header-title">Today’s Deliveries</div>
          <div class="count">{{ deliveryReport.todaysDelivery }}</div>
        </div>
      </div>
      <div class="display-flex">
        <div class="ml-4 mr-4">
          <div class="header-title">Total Orders</div>
          <div class="count">{{ deliveryReport.totalOrders }}</div>
        </div>
        <div class="ml-4 mr-4">
          <div class="header-title">Total Delivered</div>
          <div class="count">{{ deliveryReport.totalDeliveries }}</div>
        </div>
      </div>
    </div>

    <highcharts
      v-if="!isLoading"
      class="rounded-corner mt-4"
      :options="chartOptions"
      :highcharts="hcInstance"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Report",
  data() {
    return {
      hcInstance: Highcharts,

      chartOptions: {
        chart: {
          borderColor: "#FFFFFF",
          type: "area",
          plotBorderWidth: 0,
          height: "350px",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          allowDecimals: false,
          title: {
            text: "",
          },
          gridLineWidth: 1,
          gridLineDashStyle: "shortdash",
        },
        yAxis: {
          title: {
            text: "",
          },
          allowDecimals: false,
          lineWidth: 0,
          gridLineWidth: 0,
          gridLineColor: "transparent",
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "Month {point.key} :",
          pointFormat: " Order {point.y}",
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false,
            },
          },
          series: {
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.7 },
              stops: [
                [0, "#BCEA5B"],
                [2, "#FFFFFF"],
              ],
            },
          },
        },
        series: [
          {
            name: "Days",
            color: "#BCEA5B",
            data: [],
          },
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchMonthlyReport: "admin/dashboard/fetchMonthlyReport",
      fetchDeliveryReport: "admin/dashboard/fetchDeliveryReport",
    }),
  },
  computed: {
    ...mapGetters("admin/dashboard", ["monthlyReport", "deliveryReport"]),
  },
  async created() {
    this.isLoading = true;
    await this.fetchMonthlyReport();
    await this.fetchDeliveryReport();
    this.chartOptions.series[0].data = [...this.monthlyReport];
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.dashboard-report {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  height: 474px;
  color: #4a5571;
  .header-title {
    font-weight: 500;
    font-size: 14px;
  }
  .count {
    font-weight: bold;
    font-size: 20px;
  }
}
::v-deep .highcharts-credits {
  display: none;
}
</style>
