var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-report-container mb-3 mt-3"},[_c('div',{staticClass:"header-container display-flex justify-space-between align-center width-100"},[_c('h1',[_vm._v("Merchant Report")]),_c('MonthPicker',{key:_vm.monthPickerKey,attrs:{"month":_vm.monthText,"year":_vm.year},on:{"change":_vm.monthPicked,"reset":_vm.resetClicked}})],1),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center",attrs:{"size":"30"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.merchantReport,"item-key":"name","hide-default-footer":"","server-items-length":_vm.totalMerchantReport,"options":{ page: _vm.merchantReportsPage, itemsPerPage: 5 }},on:{"update:page":function($event){return _vm.updatePagination($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.company_img)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.company_img)),"name":item.company_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.company_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_c('h1',[_vm._v(_vm._s(item.company_name ? item.company_name : "-"))])])],1)]}},{key:"item.total_orders",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"orders-container display-flex align-center"},[_c('div',{staticClass:"count"},[_c('h1',[_vm._v(_vm._s(item.ordercount))]),_c('h2',{staticClass:"mt-2"},[_vm._v("Total Orders")])]),_c('div',{staticClass:"progress exact-center ml-2"},[_c('img',{attrs:{"width:":"10","height":"20","src":require("@/assets/icons/increment.svg"),"alt":"order-progress"}})])])]}},{key:"item.pendings",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"orders-container display-flex align-center"},[_c('div',{staticClass:"order-image mr-2"},[_c('img',{attrs:{"src":require("@/assets/icons/total-merchant.svg")}})]),_c('div',{staticClass:"count"},[_c('h1',[_vm._v(" "+_vm._s(_vm.getPendingOrders( item.ordercount, item.ordercompleted, item.orderfailed ))+" ")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Order Pendings")])]),_c('div',{staticClass:"progress exact-center ml-2"},[_c('img',{attrs:{"width:":"10","height":"20","src":require("@/assets/icons/increment.svg"),"alt":"order-progress"}})])])]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"orders-container display-flex align-center"},[_c('div',{staticClass:"order-image mr-2"},[_c('img',{attrs:{"src":require("@/assets/icons/frame.svg")}})]),_c('div',{staticClass:"count"},[_c('h1',[_vm._v(_vm._s(item.ordercompleted))]),_c('h2',{staticClass:"mt-2"},[_vm._v("Order Delivered")])]),_c('div',{staticClass:"progress exact-center ml-2"},[_c('img',{attrs:{"width:":"10","height":"20","src":require("@/assets/icons/increment.svg"),"alt":"order-progress"}})])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }