<template>
  <div
    class="header-outer width-100 justify-space-between align-center pl-16 pr-16"
  >
    <div class="logo-container">
      <img src="@/assets/logo.svg" alt="logo" />
    </div>
    <div :key="menuKey" class="menu-container display-flex align-center">
      <div v-for="item in items" :key="item.title">
        <div
          v-if="item.show"
          @click="selectItem(item)"
          class="menu-item position-relative cursor-pointer"
          :class="{ 'menu-item-active': item.isActive }"
        >
          <h1>{{ item.title }}</h1>
          <div class="active-menu position-absolute"></div>
        </div>
      </div>
    </div>
    <div class="user-container display-flex align-center position-relative">
      <div class="details">
        <h1 v-if="loggedUser.user_name">{{ loggedUser.user_name }}</h1>
        <h2 v-if="loggedUser.role">{{ loggedUser.role.name }}</h2>
      </div>
      <Avatar
        @click="show = !show"
        v-if="loggedUser.user_img"
        :name="loggedUser.user_name"
        :url="`${serverUrl}/${loggedUser.user_img}`"
        size="50"
        radius="12"
        :clickable="true"
      />
      <Avatar
        v-else
        @click="show = !show"
        :name="loggedUser.user_name"
        size="50"
        radius="12"
        :clickable="true"
      />
      <div
        v-click-outside="onBlur"
        v-if="show"
        class="position-absolute outer-layout"
      >
        <v-list>
          <v-list-item
            @click="onClickSettings"
            class="option-list"
            :class="{ 'selected-option': isSettings }"
          >
            <div>
              <img src="@/assets/icons/settings.svg" /><span class="ml-2">
                Settings</span
              >
            </div>
          </v-list-item>
          <v-list-item
            class="option-list"
            @click="onClickLogout"
            :class="{ 'selected-option': !isSettings }"
          >
            <div>
              <img src="@/assets/icons/logout.svg" /><span class="ml-3"
                >Logout</span
              >
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ADMIN_NAV_ITEMS, SERVER_URL } from "../../../../constants";
export default {
  name: "AdminHeader",
  props: {},
  data() {
    return {
      items: [],
      serverUrl: SERVER_URL,
      menuKey: 0,
      isSettings: true,
      show: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "admin/authentication/logout",
    }),
    selectItem(item) {
      this.items.map((menu) => {
        if (menu.route === item.route) {
          menu.isActive = true;
          this.$router.push(menu.route).catch(() => {});
        } else {
          menu.isActive = false;
        }
      });
    },
    onClickLogout() {
      this.isSettings = false;
      this.show = false;
      this.logout();
      this.$router.push("/auth/login/admin").catch(() => {});
    },
    onClickSettings() {
      this.isSettings = true;
      this.$router.push("/admin/settings").catch(() => {});
      this.show = false;
    },
    setNavItems() {
      this.items = ADMIN_NAV_ITEMS;
      this.items.map((item) => {
        switch (item.title) {
          case "Dashboard":
            item.show = this.loggedUserPermission.management_dashboard;
            break;
          case "Merchants":
            item.show = this.loggedUserPermission.view_merchants;
            break;
          case "Logistics":
            item.show = this.loggedUserPermission.view_logistics;
            break;
          case "Customers":
            item.show = this.loggedUserPermission.view_customers;
            break;
          case "Promotions":
            item.show = this.loggedUserPermission.view_promotions;
            break;
          case "Users":
            item.show = this.loggedUserPermission.view_users;
            break;
          default:
            item.show = true;
        }
      });
      this.setAdminRoute();
    },
    setAdminRoute() {
      this.items.map((menu) => {
        if (menu.route === this.$route.path) {
          menu.isActive = true;
        } else {
          menu.isActive = false;
        }
      });
    },
    onBlur() {
      this.show = false;
    },
  },
  computed: {
    ...mapGetters("admin/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
  },
  async mounted() {
    this.setNavItems();
  },
  watch: {
    $route() {
      this.setNavItems();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.header-outer {
  background: $white-color;
  height: 6rem;
  .user-container {
    text-align: right;
    .details {
      margin-right: 0.938rem;
      h1 {
        color: $secondary-color;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 0.375rem;
        width: 140px;
        word-wrap: break-word;
      }
      h2 {
        color: #999da0;
        font-weight: 500;
        font-size: 0.813rem;
      }
    }
  }
  .menu-container {
    height: 6rem;
    .menu-item {
      margin: 0 1.875rem;
      h1 {
        color: #999da0;
        font-size: 1rem;
      }
      .active-menu {
        display: none;
      }
    }

    .menu-item-active {
      h1 {
        color: $primary-color;
      }
      .active-menu {
        display: block;
        height: 0.188rem;
        width: 100%;
        bottom: -2.415rem;
        background: $primary-color;
        border-radius: 0.375rem 0.375rem 0px 0px;
      }
    }
  }
}
.outer-layout {
  width: 148px;
  height: 110px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  font-weight: normal;
  font-size: 14px;
  color: #4a5571;
  top: 50px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.selected-option {
  background: #e8eaf6;
  border-radius: 4px;
}
.option-list {
  margin: 0 6px;
}
</style>
