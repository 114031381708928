<template>
  <div class="merchant-table-container pl-7 pr-7 width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Merchant`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Merchant ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="adminMerchantCount === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        :headers="merchantHeaders"
        :items="adminMerchants"
        :expanded.sync="expanded"
        item-key="id"
        hide-default-footer
        :options="{ page: adminMerchantPage, itemsPerPage: adminMerchantLimit }"
        :server-items-length="adminMerchantCount"
        @update:page="updatePagination($event)"
        @update:items-per-page="updatePerPage($event)"
        v-else
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
          />
        </template>
        <template v-slot:item.company_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.company_img"
              :name="item.company_name"
              :url="`${serverUrl}/${item.company_img}`"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.company_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.company_name ? item.company_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.company_status="{ item }">
          <div
            class="role exact-center inactive-user"
            :class="{
              'active-user': !item.archived,
            }"
          >
            {{ item.archived ? "Inactive" : "Active" }}
          </div>
        </template>

        <template v-slot:item.contact_person="{ item }">
          <div class="name">
            {{ item.contact_person ? item.contact_person : "-" }}
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="name">
            {{ item.contact_number ? item.contact_number : "-" }}
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address ? item.address : "-" }}
          </div>
          <div class="address-2" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
        </template>

        <template v-slot:item.phone_number="{ item }">
          <div class="name">
            {{ item.phone_number ? item.phone_number : "-" }}
          </div>
        </template>

        <template v-slot:item.fax_number="{ item }">
          <div class="name">{{ item.fax_number ? item.fax_number : "-" }}</div>
        </template>

        <template v-slot:item.email_address="{ item }">
          <div class="name">
            {{ item.email_address ? item.email_address : "-" }}
          </div>
        </template>

        <template v-slot:item.website="{ item }">
          <a
            :href="formatWebsite(item.website)"
            class="website cursor-pointer"
            target="_blank"
            v-if="item.website"
          >
            {{ item.website }}
          </a>
          <div class="no-website" v-else>Not Available</div>
        </template>

        <template v-slot:item.customercount="{ item }">
          <div class="name">
            {{ item.customercount ? item.customercount : "0" }}
          </div>
        </template>

        <template v-slot:item.actions="props">
          <div
            class="action-buttons display-flex align-center"
            v-if="props.item.actions"
          >
            <ActionButton
              label="Warehouse"
              :count="props.item.warehousecount ? props.item.warehousecount : 0"
              icon="warehouse"
              width="130px"
              class="mr-4"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Warehouse', props.item.type)"
              :active="props.item.warehouseSelected"
            />
            <ActionButton
              label="Users"
              :count="props.item.usercount ? props.item.usercount : 0"
              icon="user"
              width="108px"
              class="mr-4"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Users', props.item.type)"
              :active="props.item.usersSelected"
            />
            <ActionButton
              label="Orders"
              :count="props.item.ordercount ? props.item.ordercount : 0"
              icon="orders"
              width="108px"
              :class="{ 'button-disable': props.item.archived }"
              @click="expandClicked(props, 'Orders', props.item.type)"
              :active="props.item.ordersSelected"
            />

            <v-edit-dialog
              v-if="loggedUserPermission.edit_merchants"
              :return-value.sync="props.item.action"
            >
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="onEdit(props.item)"
                  @deleteClicked="onDelete(props.item)"
                  :isActiveInactive="true"
                  @activeToggle="activeClicked(props.item)"
                  :active="!props.item.archived"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <Spinner
              class="inner-spinner-class exact-center"
              v-if="isTableLoading"
            />
            <InnerTable
              v-else
              :keyValue="innerTableKey"
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :totalItem="item.totalItem"
              :page="item.page"
              :searchValue="item.searchValue"
              :isFilter="item.isFilter"
              :hideFilter="item.hideFilter ? item.hideFilter : false"
              @updatePagination="updateInnerTablePage"
              @refresh="refreshInnerTable"
              @onSearch="onSearch"
              @filterClicked="filterClicked"
            >
              <div class="ml-4 pt-4" style="height: 120px">
                <SelectField
                  label="Logistics"
                  :data-set="['NA', ...logisticsDropdown]"
                  itemText="company_name"
                  @change="getFormData($event, 'logisticsFilter', item)"
                />
              </div>
            </InnerTable>
          </td>
        </template>
      </v-data-table>
    </div>
    <AddMerchant
      :show="showEditMerchant"
      @close="showEditMerchant = false"
      title="Edit Merchant"
      width="928"
      @saveClicked="refresh"
      :item="selectedItem"
      :key="addMerchantKey"
      :isEdit="true"
    />
  </div>
</template>

<script>
import InnerTable from "@/components/Admin/Merchant/InnerTable";
import AddMerchant from "./AddMerchant.vue";
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL, ITEMS_PER_PAGE, HTTP_REGEX } from "../../../../constants";

export default {
  name: "MerchantTableComponent",
  components: { InnerTable, AddMerchant },
  data() {
    return {
      serverUrl: SERVER_URL,
      itemsPerPage: ITEMS_PER_PAGE,
      isLoading: false,
      isTableLoading: false,
      addMerchantKey: 0,
      innerTableKey: 10,
      showEditMerchant: false,
      expanded: [],
      selectedItem: {},
      showWarehouse: false,
      deleteDialog: false,
      showUser: false,
      merchantHeaders: [
        {
          text: "Merchant Name",
          align: "start",
          sortable: false,
          value: "company_name",
          width: "250px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "company_status",
          width: "150px",
        },
        {
          text: "Contact Person",
          align: "start",
          sortable: false,
          value: "contact_person",
          width: "150px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
          width: "150px",
        },
        {
          text: "Address",
          align: "start",
          sortable: false,
          value: "address",
          width: "240px",
        },
        {
          text: "Phone No.",
          align: "start",
          sortable: false,
          value: "phone_number",
        },
        {
          text: "Fax No.",
          align: "start",
          sortable: false,
          value: "fax_number",
          width: "150px",
        },
        {
          text: "Email ID",
          align: "start",
          sortable: false,
          value: "email_address",
          width: "150px",
        },
        {
          text: "Website",
          align: "start",
          sortable: false,
          value: "website",
          width: "150px",
        },
        {
          text: "Customers",
          align: "start",
          sortable: false,
          value: "customercount",
          width: "150px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      wareHouseHeaders: [
        {
          text: "Warehouse Name",
          align: "start",
          sortable: false,
          value: "warehouse_name",
          width: "150px",
        },
        {
          text: "Contact Person",
          align: "start",
          sortable: false,
          value: "contact_person",
          width: "150px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
          width: "150px",
        },
        {
          text: "Address",
          align: "start",
          sortable: false,
          value: "address",
          width: "150px",
        },
        {
          text: "Phone No.",
          align: "start",
          sortable: false,
          value: "phone_number",
          width: "150px",
        },
        {
          text: "Fax No.",
          align: "start",
          sortable: false,
          value: "fax_number",
          width: "150px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          width: "150px",
        },
      ],
      ordersHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "150px",
        },
        {
          text: "Order No.",
          align: "start",
          sortable: false,
          value: "order_no",
          width: "150px",
        },
        {
          text: "Logistics",
          align: "start",
          sortable: false,
          value: "logistics",
          width: "150px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "200px",
        },
        {
          text: "Product Category",
          align: "start",
          sortable: false,
          value: "product_category",
          width: "200px",
        },
        {
          text: "Product Code",
          align: "start",
          sortable: false,
          value: "product_code",
          width: "150px",
        },
        {
          text: "Product Name",
          align: "start",
          sortable: false,
          value: "product_name",
          width: "300px",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          width: "280px",
        },
        {
          text: "Qty",
          align: "start",
          sortable: false,
          value: "product_quantity",
          width: "30px",
        },
        {
          text: "UOM",
          align: "start",
          sortable: false,
          value: "uom",
          width: "150px",
        },
        {
          text: "Ordered Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "150px",
        },
        {
          text: "Exp del date",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
          width: "150px",
        },
        {
          text: "Price",
          align: "start",
          sortable: false,
          value: "price",
          width: "120px",
        },
      ],
      usersHeaders: [
        {
          text: "User Name",
          align: "start",
          sortable: false,
          value: "user_name",
          width: "250px",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
          width: "250px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "user_status",
          width: "200px",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "phone_number",
          width: "250px",
        },
        // {
        //   text: "Reset Password",
        //   align: "center",
        //   sortable: false,
        //   value: "resetPassword",
        //   width: "250px",
        // },
        {
          text: "Last Login Date",
          align: "start",
          sortable: false,
          value: "last_login",
          width: "250px",
        },
        // {
        //   text: "Last Session",
        //   align: "start",
        //   sortable: false,
        //   value: "last_session",
        //   width: "250px",
        // },
        {
          text: "Created Date",
          align: "start",
          sortable: false,
          value: "created_at",
          width: "250px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
          width: "250px",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getMerchants: "admin/merchants/getMerchants",
      getMerchantById: "admin/merchants/getMerchantById",
      deleteMerchant: "admin/merchants/deleteMerchant",
      setActiveMerchant: "admin/merchants/setActiveMerchant",
      setPage: "admin/merchants/setPage",
      setLimit: "admin/merchants/setLimit",
      getMerchantUsers: "admin/merchantUsers/getUsers",
      setMerchantUserPage: "admin/merchantUsers/setPage",
      setUserSearchQuery: "admin/merchantUsers/setSearchQuery",
      setMerchantUserDateRange: "admin/merchantUsers/setDateRange",
      getMerchantWarehouse: "admin/warehouse/getWarehouses",
      setMerchantWarehousePage: "admin/warehouse/setPage",
      setWarehouseSearchQuery: "admin/warehouse/setSearchQuery",
      setWarehouseDateRange: "admin/warehouse/setDateRange",
      getMerchantOrders: "admin/orders/getOrders",
      setOrdersPage: "admin/orders/setPage",
      setOrdersSearchQuery: "admin/orders/setSearchQuery",
      setOrdersDateRange: "admin/orders/setDateRange",
      getLogisticsDropdown: "admin/orders/getLogisticsDropdown",
      setLogisticFilter: "admin/orders/setLogisticFilter",
      setLogisticIDEmpty: "admin/orders/setLogisticIDEmpty",
    }),
    async getFormData(value, fieldName, item) {
      if (fieldName === "logisticsFilter") {
        if (value === "NA") {
          await this.setLogisticFilter({ id: item.id, logistic_id: "" });
          item.innerItems = this.orders;
          item.page = this.ordersPage;
          item.totalItem = this.totalOrders;
          this.innerTableKey += 1;
          return;
        }
        await this.setLogisticFilter({ id: item.id, logistic_id: value.id });
        item.innerItems = this.orders;
        item.page = this.ordersPage;
        item.totalItem = this.totalOrders;
        this.innerTableKey += 1;
      }
    },
    formatWebsite(url) {
      if (!HTTP_REGEX.test(url)) {
        url = "http://" + url;
      }
      return url;
    },
    async setUserData(item) {
      this.isTableLoading = true;
      try {
        await this.getMerchantUsers(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async setOrdersData(item) {
      this.isTableLoading = true;
      try {
        await this.getMerchantOrders(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async setWarehouseData(item) {
      this.isTableLoading = true;
      try {
        await this.getMerchantWarehouse(item);
      } catch (err) {
        console.log(err);
        this.isTableLoading = false;
      }
    },
    async filterClicked(selectedItem) {
      this.adminMerchants.map(async (item) => {
        if (item.id === selectedItem?.merchant?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setMerchantUserDateRange(selectedItem.value);
              this.setMerchantUserPage(1);
              await this.getMerchantUsers(item);
              item.innerItems = this.merchantUsers;
              item.page = this.merchantUserPage;
              item.totalItem = this.totalMerchantUsers;
              item.usercount = this.totalMerchantUsers;
              item.isFilter = this.userFromDate && this.userToDate;
              this.innerTableKey += 1;
              break;
            case "Warehouse":
              this.setWarehouseDateRange(selectedItem.value);
              this.setMerchantWarehousePage(1);
              await this.getMerchantWarehouse(item);
              item.innerItems = this.warehouses;
              item.page = this.merchantWarehousePage;
              item.totalItem = this.totalWarehouse;
              item.warehousecount = this.totalWarehouse;
              item.isFilter = this.warehouseFromDate && this.warehouseToDate;
              this.innerTableKey += 1;
              break;
            case "Orders":
              this.setLogisticIDEmpty();
              this.setOrdersDateRange(selectedItem.value);
              this.setOrdersPage(1);
              await this.getMerchantOrders(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.ordercount = this.totalOrders;
              item.isFilter = this.ordersFromDate && this.ordersToDate;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async onSearch(selectedItem) {
      this.adminMerchants.map(async (item) => {
        if (item.id === selectedItem?.merchant?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setUserSearchQuery(selectedItem.value);
              this.setMerchantUserPage(1);
              await this.getMerchantUsers(item);
              item.innerItems = this.merchantUsers;
              item.page = this.merchantUserPage;
              item.usercount = this.totalMerchantUsers;
              item.totalItem = this.totalMerchantUsers;
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
            case "Warehouse":
              this.setWarehouseSearchQuery(selectedItem.value);
              this.setMerchantWarehousePage(1);
              await this.getMerchantWarehouse(item);
              item.innerItems = this.warehouses;
              item.page = this.merchantWarehousePage;
              item.totalItem = this.totalWarehouse;
              item.warehousecount = this.totalWarehouse;
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
            case "Orders":
              this.setOrdersSearchQuery(selectedItem.value);
              this.setOrdersPage(1);
              await this.getMerchantOrders(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.ordercount = this.totalOrders;
              item.searchValue = selectedItem.value;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async expandClicked(slotData, type, selectedType) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1 && selectedType === type) {
        this.expanded.splice(indexExpanded, 1);
        this.adminMerchants.map((item) => {
          if (item.name === slotData.item.name) {
            item.warehouseSelected = false;
            item.usersSelected = false;
            item.ordersSelected = false;
            item.selected = false;
            item.isFilter = false;
            this.setMerchantUserDateRange({});
            this.setWarehouseDateRange({});
            this.setOrdersDateRange({});
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.adminMerchants.map(async (item) => {
          if (item.id === slotData.item.id) {
            switch (type) {
              case "Warehouse":
                this.setWarehouseSearchQuery("");
                this.setWarehouseDateRange({});
                item.isFilter = this.warehouseFromDate && this.warehouseToDate;
                item.warehouseSelected = true;
                item.usersSelected = false;
                item.ordersSelected = false;
                item.selected = true;
                this.setMerchantWarehousePage(1);
                await this.setWarehouseData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.hideFilter = false;
                item.innerHeaders = this.computedWarehouseHeaders;
                item.innerItems = this.warehouses;
                item.page = this.merchantWarehousePage;
                item.totalItem = this.totalWarehouse;
                item.searchValue = "";
                item.type = type;
                break;
              case "Users":
                this.setUserSearchQuery("");
                this.setMerchantUserDateRange({});
                item.warehouseSelected = false;
                item.isFilter = this.userFromDate && this.userToDate;
                item.usersSelected = true;
                item.hideFilter = false;
                item.ordersSelected = false;
                item.selected = true;
                this.setMerchantUserPage(1);
                await this.setUserData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.innerHeaders = this.computedUsersHeaders;
                item.innerItems = this.merchantUsers;
                item.page = this.merchantUserPage;
                item.totalItem = this.totalMerchantUsers;
                item.searchValue = "";
                item.type = type;
                break;
              case "Orders":
                this.getLogisticsDropdown(item.id);
                this.setOrdersSearchQuery("");
                this.setOrdersDateRange({});
                //If Scheduled date is mentioned in main filter - Inner filter is based on that
                if (this.type === "sorted") {
                  this.setOrdersDateRange({
                    dateRange: { fromDate: this.fromDate, toDate: this.toDate },
                    type: "",
                  });
                  item.hideFilter = true;
                } else {
                  this.setOrdersDateRange({});
                  item.hideFilter = false;
                }
                item.warehouseSelected = false;
                item.isFilter = this.ordersFromDate && this.ordersToDate;
                item.usersSelected = false;
                item.ordersSelected = true;
                item.selected = true;
                this.setOrdersPage(1);
                await this.setOrdersData(item);
                this.innerTableKey += 1;
                this.isTableLoading = false;
                item.innerHeaders = this.ordersHeaders;
                item.innerItems = this.orders;
                item.page = this.ordersPage;
                item.totalItem = this.totalOrders;
                item.searchValue = "";
                item.type = type;
                break;
            }
          } else {
            item.warehouseSelected = false;
            item.usersSelected = false;
            item.ordersSelected = false;
            item.selected = false;
          }
        });
      }
    },
    onDelete(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      await this.deleteMerchant(this.selectedItem);
      this.onDeleteCancel();
      await this.getMerchants();
    },
    async activeClicked(item) {
      item.archived
        ? await this.setActiveMerchant(item)
        : await this.deleteMerchant(item);
      await this.getMerchants();
    },
    async updatePagination(value) {
      this.expanded = [];
      this.setPage(value);
      await this.fetchData();
    },
    async updatePerPage(value) {
      this.expanded = [];
      this.setLimit(value);
      await this.fetchData();
    },
    async refreshInnerTable(selectedItem) {
      this.adminMerchants.map(async (item) => {
        if (item.id === selectedItem?.merchant?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setUserSearchQuery("");
              this.innerTableKey += 1;
              await this.setUserData(item);
              item.innerItems = this.merchantUsers;
              item.page = this.merchantUserPage;
              item.totalItem = this.totalMerchantUsers;
              item.usercount = this.totalMerchantUsers;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
            case "Warehouse":
              this.setWarehouseSearchQuery("");
              this.innerTableKey += 1;
              await this.setWarehouseData(item);
              item.innerItems = this.warehouses;
              item.page = this.merchantWarehousePage;
              item.totalItem = this.totalWarehouse;
              item.warehousecount = this.totalWarehouse;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
            case "Orders":
              this.setOrdersSearchQuery("");
              this.innerTableKey += 1;
              await this.setOrdersData(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.warehousecount = this.totalOrders;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
          }
        }
      });
    },
    async updateInnerTablePage(selectedItem) {
      this.adminMerchants.map(async (item) => {
        if (item.id === selectedItem?.merchant?.id) {
          switch (selectedItem.type) {
            case "Users":
              this.setUserSearchQuery("");
              this.setMerchantUserPage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setUserData(item);
              item.innerItems = this.merchantUsers;
              item.page = this.merchantUserPage;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
            case "Warehouse":
              this.setWarehouseSearchQuery("");
              this.setMerchantWarehousePage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setWarehouseData(item);
              item.innerItems = this.warehouses;
              item.page = this.merchantWarehousePage;
              item.totalItem = this.totalWarehouse;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
            case "Orders":
              this.setOrdersSearchQuery("");
              this.setOrdersPage(selectedItem.page);
              this.innerTableKey += 1;
              await this.setOrdersData(item);
              item.innerItems = this.orders;
              item.page = this.ordersPage;
              item.totalItem = this.totalOrders;
              item.searchValue = "";
              this.isTableLoading = false;
              break;
          }
        }
      });
    },
    onEdit(item) {
      this.selectedItem = item;
      this.showEditMerchant = true;
      this.addMerchantKey += 1;
    },
    async refresh() {
      this.showEditMerchant = false;
      await this.fetchData();
    },
    async fetchData() {
      this.isLoading = true;
      await this.getMerchants();
      this.isLoading = false;
    },
  },
  async created() {
    this.expanded = [];
    this.setMerchantUserPage(1);
    this.setMerchantWarehousePage(1);
    await this.fetchData();
  },
  computed: {
    computedWarehouseHeaders() {
      this.wareHouseHeaders.map((item, i) => {
        if (
          item.text === "Actions" &&
          !this.loggedUserPermission.edit_merchants
        ) {
          this.wareHouseHeaders.splice(i, 1);
        }
      });
      return this.wareHouseHeaders;
    },
    computedUsersHeaders() {
      this.usersHeaders.map((item, i) => {
        if (
          item.text === "Actions" &&
          !this.loggedUserPermission.edit_merchants
        ) {
          this.usersHeaders.splice(i, 1);
        }
      });
      return this.usersHeaders;
    },
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
    ...mapGetters("admin/merchants", [
      "adminMerchantCount",
      "adminMerchants",
      "adminMerchantPage",
      "adminMerchantLimit",
      "fromDate",
      "toDate",
      "type",
    ]),
    ...mapGetters("admin/merchantUsers", [
      "merchantUsers",
      "totalMerchantUsers",
      "merchantUserPage",
      "userFromDate",
      "userToDate",
    ]),
    ...mapGetters("admin/warehouse", [
      "warehouses",
      "totalWarehouse",
      "merchantWarehousePage",
      "warehouseFromDate",
      "warehouseToDate",
    ]),
    ...mapGetters("admin/orders", [
      "orders",
      "totalOrders",
      "ordersPage",
      "ordersFromDate",
      "ordersToDate",
      "logisticsDropdown",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}

.merchant-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .button-disable {
    pointer-events: none;
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .website {
    color: #2b5cdb;
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: underline;
  }
  .no-website {
    color: #4a557180;
    font-weight: 600;
    font-size: 0.875rem;
  }
}
</style>
