<template>
  <div>
    <CommonToolbar
      title="Logistics"
      :primaryButtonLabel="
        loggedUserPermission.edit_logistics &&
        loggedUserPermission.view_logistics &&
        'Add Logistics'
      "
      :secondaryButtonLabel="loggedUserPermission.view_logistics && 'Export'"
      :isSearch="loggedUserPermission.view_logistics"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_logistics"
    />
    <AddLogistics
      :width="928"
      :show="show"
      @close="show = false"
      title="Add Logistic"
      @saveClicked="refresh"
      :key="addKey"
    />
    <TableSection v-if="loggedUserPermission.view_logistics" :key="tableKey" />
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddLogistics from "./AddLogistics.vue";
import TableSection from "./TableSection.vue";
export default {
  name: "AdminLogistics",
  components: {
    AddLogistics,
    TableSection,
  },
  data() {
    return {
      show: false,
      tableKey: 0,
      addKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin/logistics", ["fromDate", "toDate"]),
    ...mapGetters("admin/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      setSearchQuery: "admin/logistics/setSearchQuery",
      setDateRange: "admin/logistics/setDateRange",
      exportLogistics: "admin/logistics/exportLogistics",
      setPage: "admin/logistics/setPage",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    addClick() {
      this.addKey += 1;
      this.show = true;
    },
    async exportClick() {
      await this.exportLogistics();
    },
    filterClicked(value) {
      this.setPage(1);
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
    refresh() {
      this.show = false;
      this.tableKey += 1;
      this.addKey += 1;
    },
  },
  created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    this.tableKey += 1;
  },
};
</script>

<style lang="scss" scoped></style>
